import * as React from 'react';
//import AppBar from '@mui/material/AppBar';
//import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
//import Grid from '@mui/material/Grid';
//import StarIcon from '@mui/icons-material/StarBorder';
//import createTheme from '@material-ui/core/styles';
//import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
//import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { withStyles } from "@material-ui/core/styles";
import symbolBlack from "./SVG/symbol-black.svg";
import beer from "./SVG/beer.png"
import bracelet from "./SVG/Bracelet.png"
import necklace from "./SVG/Necklace.png"
import bubbleGun from "./SVG/Bubble-Gun.png"
import flight from "./SVG/flight.png"
import wine from "./SVG/wine.png"
import punch from "./SVG/punch.png"
import './stage-style.css';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import Alert from '@mui/material/Alert';
import symbol from "../CK_Symbol.svg";
import PerformerDataService from "../services/PerformerService";
//import UserService from "../services/UserService";
import { useHistory, Link /*Redirect, Route*/ } from 'react-router-dom';
import { useState, useEffect } from "react";
/* THIS MAKES INCREMENT COUNTER WORK */
import firebase from 'firebase/compat/app';
import { db, auth } from "../firebase";
/* THIS MAKES INCREMENT COUNTER WORK */

//////Root Style///////////////////
const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  }
})(Typography);
//////////////////////////////////

////////Main/////////////////
function PricingContent() {

  //Reservations Initial State
  const initialReservationState = {
    timePlaced: null,
    email: auth().currentUser.email,
    firstName: "",
    lastName: "",
    sizeParty: null,
  };

  //Orders Initial State
  const initialOrderState = {
    bracelet: null,
    necklace: null,
    beer: null,
    bubbleGun: null,
  };

  /* use react-firebase-hooks */
  const [orderNumber, /*setOrderNumber*/ ] = useState(0);
  /////////////////

  /* get current user email and coins */
  /*
  db.collection('orderNumber').doc('current')
  .onSnapshot((doc) => {
      console.log("Subscribed to: ", doc.data());
      setOrderNumber(doc.data().orderNumber);
  });
  */

  //State Setters
  //const [resOpen, setResOpen] = useState(false);
  const [fanCoins, setFanCoins] = useState(0);
  const [isAffiliate, setIsAffiliate] = useState(false);
  const [guestsRes, setResGuests] = useState(null);
  const [reservationName, setReservationName] = useState(initialReservationState);
  const [orderQuantity250, setOrderQuantity250] = useState(null);
  const [orderQuantity500, setOrderQuantity500] = useState(null);
  const [orderQuantity550, setOrderQuantity550] = useState(null);
  const [orderQuantity1000, setOrderQuantity1000] = useState(null);
  const [orderQuantity1350, setOrderQuantity1350] = useState(null);
  const [orderQuantity1400, setOrderQuantity1400] = useState(null);
  const [orderQuantity1450, setOrderQuantity1450] = useState(null);
  //const [orderQuantity, setOrderQuantity] = useState([]);
  const [orderItem, setOrderItem] = useState(initialOrderState);

  //get current Koin count
  useEffect(() => {
    async function get(){
      const userRef = db.collection('users').doc(auth().currentUser.email);
      const doc = await userRef.get();
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        console.log('User Koins:', doc.data().coins);
        setFanCoins(doc.data().coins);
        setIsAffiliate(doc.data().affiliate);
      }
    }
    get()
  }, [])

  //Input Handlers////////////////////

  //Reservations
  const handlePartySize = (event) => {
    setResGuests(event.target.value);
    console.log(guestsRes);
  };

  const handleNameInput = event => {
    const { name, value } = event.target;
    setReservationName({ ...reservationName, [name]: value });
    console.log({ value });
  };

  //Orders
  const handleOrderQuantity250 = (event) => {
    setOrderQuantity250(event.target.value);
    const { name, value } = event.target;
    setOrderItem({ ...orderItem, [name]: value });
    console.log(orderQuantity250);
  };

  const handleOrderQuantity500 = (event) => {
    setOrderQuantity500(event.target.value);
    const { name, value } = event.target;
    setOrderItem({ ...orderItem, [name]: value });
    console.log(orderQuantity500);
  };
  
  const handleOrderQuantity550 = (event) => {
    setOrderQuantity550(event.target.value);
    const { name, value } = event.target;
    setOrderItem({ ...orderItem, [name]: value });
    console.log(orderQuantity550);
  };

  const handleOrderQuantity1000 = (event) => {
    setOrderQuantity1000(event.target.value);
    const { name, value } = event.target;
    setOrderItem({ ...orderItem, [name]: value });
    console.log(orderQuantity1000);
  };
  
  const handleOrderQuantity1350 = (event) => {
    setOrderQuantity1350(event.target.value);
    const { name, value } = event.target;
    setOrderItem({ ...orderItem, [name]: value });
    console.log(orderQuantity1350);
  };

  const handleOrderQuantity1400 = (event) => {
    setOrderQuantity1400(event.target.value);
    const { name, value } = event.target;
    setOrderItem({ ...orderItem, [name]: value });
    console.log(orderQuantity1400);
  };

  const handleOrderQuantity1450 = (event) => {
    setOrderQuantity1450(event.target.value);
    const { name, value } = event.target;
    setOrderItem({ ...orderItem, [name]: value });
    console.log(orderQuantity1450);
  };

  ////////////////////////////////////
  
  //For Redirect to Transactions
  const History = useHistory();

  //Send Confirmation Email
  const sendEmail = () => {

    const url = "https://script.google.com/macros/s/AKfycbzavnEyAUFItIJiU794pgcE8Q1JsKUVggyXjfa7V34C16yXAVGFkx6s0xUPMHWbmaLT/exec";
    let d2 = new Date();
    //post Coins
    fetch(url,{
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'no-cors', // no-cors, *cors, same-origin 
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //credentials: 'omit', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        Navigate: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ timeStamp: d2.toLocaleString(), sender: auth().currentUser.email, coins: "none", performer: "none", phone: "none", message: "You're Reservation was made!", subject: "🎤 😁 Can't wait to see YOU at the SHOW!!!", title: "We've received your Reservation." }) // body data type must match "Content-Type" header
      });
  }

  //Log Affiliate Sign Up to Firebase
  const affiliateSignUp = () => {

    //////BATCH WRITE Affiliate Sign Up and TRANSACTION TO FIREBASE

    let d = new Date();
    let time = d.toLocaleString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });

    var amount = 0;
    var dt = new Date();
    var utcDate = dt.toString();
    var date = dt.toLocaleDateString();
    var dateTime = date + " - " + time;

    const batch = db.batch();
    const userRef = db.collection('users').doc(auth().currentUser.email);
    const transactionRef = db.collection('users').doc(auth().currentUser.email).collection('transactions').doc(utcDate);

    batch.set(userRef, { affiliate: true }, { merge: true });
    batch.set(transactionRef, { coins: amount, rewarded: "Affiliate Sign Up", time: dateTime });
    batch.commit();

  ////////////////////////////////////////////
    
    //Send to Transactions Page
    History.push('/transactions');
  }
  
  //Log Reservation to Firebase
  const placeReservation = () => {
    
    if (reservationName.firstName && reservationName.lastName && guestsRes) {
      console.log(reservationName.firstName);

    //let d2 = new Date();
    let d = new Date();
    let time = d.toLocaleString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    let month = d.getMonth() + 1;	// Month	[mm]	(1 - 12)
    let day = d.getDate();		// Day		[dd]	(1 - 31)
    //let year = d.getFullYear();	// Year		[yyyy]

    var dataRes = {
      doc: auth().currentUser.email+"-"+time,
      timePlaced: month + "/" + day + "/" + time,
      email: auth().currentUser.email,
      firstName: reservationName.firstName,
      lastName: reservationName.lastName,
      sizeParty: guestsRes,
      affiliate: isAffiliate
    };

    //////BATCH WRITE RESERVATION TRANSACTION TO FIREBASE

      console.log('STARTED RESERVATION');
      var amount = guestsRes*50;
      var dt = new Date();
      var utcDate = dt.toString();
      var date = dt.toLocaleDateString();
      //var time = dt.toLocaleTimeString();
      var dateTime = date + " - " + time;
  
      const batch = db.batch();
      //const performerRef = db.collection('performers').doc(perfID);
      const userRef = db.collection('users').doc(auth().currentUser.email);
      const transactionRef = db.collection('users').doc(auth().currentUser.email).collection('transactions').doc(utcDate);
      //const increment = firebase.firestore.FieldValue.increment(amount);
      const decrement = firebase.firestore.FieldValue.increment(-amount);
            
      //batch.set(performerRef, { coins: increment }, { merge: true });
      //console.log('PERFORMER SET');
      batch.set(userRef, { coins: decrement }, { merge: true });
      batch.set(transactionRef, { coins: amount, rewarded: "Reservation", time: dateTime });
      console.log('USER SET');
      //batch.set(userRef2, { rewarded: '20 Crypto Koins to ' +  perfID });
      //console.log('USER TRANSACTION LOGGED');
      batch.commit();

    ////////////////////////////////////////////

    //console.log(JSON.stringify(data, null, 2));
    PerformerDataService.createReservation(dataRes)
    .then(() => {
      console.log("The Reservation was Logged!");
    })
    .catch((e) => {
      console.log(e);
    })
    .then(()=>{
      // send verification mail.
      sendEmail();
      alert("Reservation Email sent to: " + auth().currentUser.email);
      //Redirect to Transactions
      History.push('/transactions');
    })
    .catch(alert);
  };
}

//Log Reservation to Firebase
const placeOrder = () => {

  if (orderItem) {
    console.log(orderItem);

  //let d2 = new Date();
  let d = new Date();
  let time = d.toLocaleString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
  let month = d.getMonth() + 1;	// Month	[mm]	(1 - 12)
  let day = d.getDate();		// Day		[dd]	(1 - 31)
  let year = d.getFullYear();	// Year		[yyyy]

  //Generate Code
  const characters = ["Chip n Dale", "Bunny Foo Foo", "The Great Gazoo", "Scrooge McDuck", "Squeedly Spooge", "Artoo-Detoo", "Gossamer", "Fraggles", "Boombah", "Yip Yips", "Cheshire Cat", "Ren Hoek", "Stimpy", "Lucy Lu", "Rainbow Dash", "Wafflebot", "Gumbi", "Toucan Sam", "Yo Gabba Gabba", "Rat Fink", "Cookie Puss", "Puss in Boots", "Pippi Longstockings", "WIlly Wonka", "Peter Pan", "Buckaroo Banzai", "Klondike Kat", "Invader Zim", "Yosemite Sam", "Lectronimo", "Oompa-Loompas", "Slowpoke Rodriguez", "Pepé Le Pew", "Michigan J Frog", "Chilly Willy", "Scratchy", "Itchy", "Boneless Chicken", "Tweedledum", "Tweedledee", "Cow and Chicken", "Chaka", "Sleestak", "Koko", "Spooky Space Kook", "Clyde Crashcup", "Bullwinkle", "Fooby", "Smikka Smikka Smoodoo", "Throbulator", "Shloonktapooxis", "Minimoose", "MiMi", "Spoopty", "Mooshy", "Skikkiks", "Mr. Sludgy", "Count Cocofang", "Squishy Hugger", "Peepi", "Gir", "Zim", "Shnooky", "Captain Caveman", "Stinky Smurf", "Kaboobie", "Baba Looey", "Fuzzy Lumpkins", "Gogo Dodo", "Goopy Geer", "Elmer Fudd", "Elroy Jetson", "Harvey Birdman", "Humpty Dumpty", "Mojo Jojo", "Squiddly Diddly", "Jabberjaw", "Pickles Oblong", "MC Pee Pants", "Frylock", "Master Shake", "Meatwad", "Yertle the Turtle", "Jerry the Bellybutton Elf", "Jimminy Lummox", "Stinky Wizzleteats", "Mrs. Buttloaves", "Powdered Toast Man", "SpongeBob SquarePants", "Winnie the Pooh", "Boo Boo", "Foghorn Leghorn", "Scooby Doo", "Grape Ape", "Hong Kong Phooey"];
  const random = Math.floor(Math.random() * characters.length);
  //console.log(characters[random], random*4);
  var numberGen = random*4;
  var characterGen = characters[random];
  var codeGenerated = characterGen + " - " + numberGen;
  var createOrderNumber = orderNumber+1;

  //Gather Order Info
  var dataOrder = {
    code: codeGenerated,
    email: auth().currentUser.email,
    order: orderItem,
    orderNumber: createOrderNumber,
    //quantity: orderQuantity,
    timePlaced: month + "-" + day + "-" + year + " - " + time,
  };

  //////BATCH WRITE ORDER TRANSACTION TO FIREBASE

  console.log('STARTED ORDER');
  
  var amountTotal = (orderQuantity250*250) + (orderQuantity500*500) + (orderQuantity550*550) + (orderQuantity1000*1000) + (orderQuantity1350*1350) + (orderQuantity1400*1400) + (orderQuantity1450*1450); 
  var dt = new Date();
  var utcDate = dt.toString();
  //var date = dt.toLocaleDateString();
  //var time = dt.toLocaleTimeString();
  var dateTime = month + "/" + day + "/" + year + " - " + time;

  const batch = db.batch();
  //const performerRef = db.collection('performers').doc(perfID);
  const userRef = db.collection('users').doc(auth().currentUser.email);
  const transactionRef = db.collection('users').doc(auth().currentUser.email).collection('transactions').doc(utcDate);
  const orderNumberRef = db.collection('orderNumber').doc('current');
  const increment = firebase.firestore.FieldValue.increment(1);
  const decrement = firebase.firestore.FieldValue.increment(-amountTotal);
        
  //batch.set(performerRef, { coins: increment }, { merge: true });
  //console.log('PERFORMER SET');
  batch.set(userRef, { coins: decrement }, { merge: true });
  batch.set(orderNumberRef, { orderNumber: increment }, { merge: true });
  batch.set(transactionRef, { coins: amountTotal, rewarded: "Order", time: dateTime, code: codeGenerated, order: orderItem, transactionOrderNumber: dataOrder.orderNumber });
  console.log('USER SET');
  //batch.set(userRef2, { rewarded: '20 Crypto Koins to ' +  perfID });
  //console.log('USER TRANSACTION LOGGED');
  batch.commit();

  ////////////////////////////////////////////

  //console.log(JSON.stringify(data, null, 2));
  PerformerDataService.createOrder(dataOrder)
  .then(() => {
    console.log("The Order was Logged!");
  })
  .catch((e) => {
    console.log(e);
  })
  .then(()=>{
    // send verification mail.
    //sendEmail();
    //alert("Reservation Email sent to: " + auth().currentUser.email);
    //Redirect to Sign in
    History.push('/transactions');
  })
  .catch(alert);

  };
}

const stickyStyle = {
  backgroundColor: "#F8F8F8",
  borderTop: "3px solid #E7E7E7",
  textAlign: "center",
  paddingTop: "20px",
  paddingLeft: "20px",
  paddingRight: "20px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "8rem",
  width: "100%",
  zIndex: "6500",
};

const stickyButtonStyle = {
  bottom: "0.75rem",
  zIndex: "7000",
};

  return (
    <>
    {orderQuantity250 || orderQuantity500 || orderQuantity550 || orderQuantity1000 || orderQuantity1350 || orderQuantity1400 || orderQuantity1450 > 0 ? <div style={stickyStyle}>
    {fanCoins > ((orderQuantity250*250) + (orderQuantity500*500) + (orderQuantity550*550) + (orderQuantity1000*1000) + (orderQuantity1350*1350) + (orderQuantity1400*1400) + (orderQuantity1450*1450)) ?
    <Button
      style={stickyButtonStyle}
      className="shopFont"
      fullWidth
      variant='contained'
      onClick={placeOrder}
      >PLACE ORDER
    </Button> : 
    <>
    <Link to={"/buy"}>
      <Alert variant="outlined" severity="error">
        <div className='alarm'><img src={symbol} className='symbol' alt="ck"/>Koins Too Low<br/><TouchAppIcon/>TOP UP NOW<TouchAppIcon/></div>
      </Alert>
    </Link>
    </>
    }
    <>Total: {(orderQuantity250*250) + (orderQuantity500*500) + (orderQuantity550*550) + (orderQuantity1000*1000) + (orderQuantity1350*1350) + (orderQuantity1400*1400) + (orderQuantity1450*1450)}</>
    </div> : ""}
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      {/* Hero unit */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 1, pb: 6 }}>
      <WhiteTextTypography variant="h5" align="center" color="text.secondary" component="h2">
          Trade your<br/>
        </WhiteTextTypography>
        <WhiteTextTypography
          component="h1"
          variant=""
          align="center"
          color="text.primary"
          className="shopFont2Smaller"
          gutterBottom
        >
      {/*<img className='rewardKoinLabel' src={symbol} alt="CK"/>*/}Crypto Koins<span className='shopFontTM'>™</span>
        </WhiteTextTypography>
        <WhiteTextTypography variant="h5" align="center" color="text.secondary" component="h2">
          for cool stuff!
        </WhiteTextTypography>
      </Container>
      {/* End hero unit */}

      <Container maxWidth="md" component="main">
              
{/*RESERVATIONS*/}
              <Card>
                <CardHeader
                  title={
                    <Typography className='text-center shopFont2' component="h1" variant="" color="text.primary">{isAffiliate ? "Affiliate " : ""}Reservations</Typography>
                  }
                  subheader={<h5><b><img className='storeKoin2' src={symbolBlack} alt="CK"/>50 per Person</b></h5>}
                  titleTypographyProps={{ align: 'center' }}
                  /*action={'title'}*/
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[300]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                
                  <Typography className="shopFont" variant="h5" color="text.secondary">
              
                  </Typography>
                  
                  
                    <div className="form-group">
                    <label htmlFor="stageName">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      required
                      onChange={handleNameInput}
                      name="firstName"
                    />
                    </div>

                    <div className="form-group">
                    <label htmlFor="lastName">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      required
                      onChange={handleNameInput}
                      name="lastName"
                    />
                    </div>

                    <div className="form-group">
                    <label htmlFor="partySize">
                    Number of People
                    </label>
                    <select className="form-control" onChange={handlePartySize} id="partySize" name="partySize" required type="text">
                      <option></option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                    </select>
                    </div>
                    
 

                {guestsRes > 0 ? <Typography className='text-center shopFont2' component="" variant="caption">
                {guestsRes * 50} CK
                </Typography> : ""}
                  
                </CardContent>

                <CardActions>
                  {fanCoins >= guestsRes * 50 ?
                  <Button
                  className="shopFont"
                  fullWidth
                  variant='contained'
                  onClick={placeReservation}
                  >Place Reservation</Button>
                  :
                  <>
                  <Link to={"/buy"}>
                    <Alert variant="outlined" severity="error">
                      <div className='alarm'><img src={symbol} className='symbol' alt="ck"/>Koins Too Low<br/><TouchAppIcon/>TOP UP NOW<TouchAppIcon/></div>
                    </Alert>
                  </Link>
                  </>
                  }
                </CardActions>
                
                
              </Card>
{/*FLIGHT*/}
              <Card>
                <CardHeader
                  title={
                    <Typography className='text-center shopFont2' component="h1" variant="" color="text.primary"><img className='storeKoin2' src={symbolBlack} alt="CK"/>1450</Typography>
                  }
                  /*subheader={<h5><b><img className='storeKoin2' src={symbolBlack} alt="CK"/>50 per Person</b></h5>}*/
                  titleTypographyProps={{ align: 'center' }}
                  /*action={'title'}*/
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[300]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Typography className="shopFont" variant="h5" color="text.secondary">
                  <>Flight of Craft Beer</>
                  </Typography>
                  <h5>*See menu for options</h5>
                  <ul>
                    <li className='MuiTypography-root MuiTypography-subtitle1 MuiTypography-alignCenter css-118o8hd-MuiTypography-root'>
                  <img className='text-center' src={flight} alt='CK'/>
                  <h5>Customize Your Own Flight</h5>
                    </li>
                  </ul>
                  <div className="form-group">
                  <label htmlFor="flight">
                  Quantity
                  </label>
                  <select className="form-control" onChange={handleOrderQuantity1450} id="flight" name="flight" required type="text">
                    <option></option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                  </select>
                  </div>

                {orderQuantity1450 > 0 ? <Typography className='text-center shopFont2' component="" variant="caption" >
                {orderQuantity1450 * 1450} CK
                </Typography> : ""}
                  
                </CardContent>
              </Card>
{/*BUBBLE GUN*/}
              <Card>
                <CardHeader
                  title={
                    <Typography className='text-center shopFont2' component="h1" variant="" color="text.primary"><img className='storeKoin2' src={symbolBlack} alt="CK"/>1400</Typography>
                  }
                  /*subheader={<h5><b><img className='storeKoin2' src={symbolBlack} alt="CK"/>50 per Person</b></h5>}*/
                  titleTypographyProps={{ align: 'center' }}
                  /*action={'title'}*/
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[300]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Typography className="shopFont" variant="h5" color="text.secondary">
                  <>Bubble Gun</>
                  </Typography>
                  <ul>
                    <li className='MuiTypography-root MuiTypography-subtitle1 MuiTypography-alignCenter css-118o8hd-MuiTypography-root'>
                  <img className='text-center' src={bubbleGun} alt='CK'/>
                  <h5>LED Light Up 6 Inch Bubble Gun</h5>
                    </li>
                  </ul>
                  <div className="form-group">
                  <label htmlFor="bubbleGun">
                  Quantity
                  </label>
                  <select className="form-control" onChange={handleOrderQuantity1400} id="bubbleGun" name="bubbleGun" required type="text">
                    <option></option>
                    <option name="bubbleGun" value="1">1</option>
                    <option name="bubbleGun" value="2">2</option>
                    <option name="bubbleGun" value="3">3</option>
                    <option name="bubbleGun" value="4">4</option>
                    <option name="bubbleGun" value="5">5</option>
                    <option name="bubbleGun" value="6">6</option>
                    <option name="bubbleGun" value="7">7</option>
                    <option name="bubbleGun" value="8">8</option>
                  </select>
                  </div>

                {orderQuantity1400 > 0 ? <Typography className='text-center shopFont2' component="" variant="caption" >
                {orderQuantity1400 * 1400} CK
                </Typography> : ""}
                  
                </CardContent>
              </Card>
{/*BEER*/}
              <Card>
                <CardHeader
                  title={
                    <Typography className='text-center shopFont2' component="h1" variant="" color="text.primary"><img className='storeKoin2' src={symbolBlack} alt="CK"/>1350</Typography>
                  }
                  /*subheader={<h5><b><img className='storeKoin2' src={symbolBlack} alt="CK"/>50 per Person</b></h5>}*/
                  titleTypographyProps={{ align: 'center' }}
                  /*action={'title'}*/
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[300]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Typography className="shopFont" variant="h5" color="text.secondary">
                  <>Craft Beer</>
                  </Typography>
                  <h5>*See menu for options</h5>
                  <ul>
                    <li className='MuiTypography-root MuiTypography-subtitle1 MuiTypography-alignCenter css-118o8hd-MuiTypography-root'>
                  <img className='text-center' src={beer} alt='CK'/>
                  <h5>1 Cold Frosty Draught</h5>
                    </li>
                  </ul>
                  <div className="form-group">
                  <label htmlFor="beer">
                  Quantity
                  </label>
                  <select className="form-control" onChange={handleOrderQuantity1350} id="beer" name="beer" required type="text">
                    <option></option>
                    <option name="beer" value="1">1</option>
                    <option name="beer" value="2">2</option>
                    <option name="beer" value="3">3</option>
                    <option name="beer" value="4">4</option>
                    <option name="beer" value="5">5</option>
                    <option name="beer" value="6">6</option>
                    <option name="beer" value="7">7</option>
                    <option name="beer" value="8">8</option>
                  </select>
                  </div>

                {orderQuantity1350 > 0 ? <Typography className='text-center shopFont2' component="" variant="caption" >
                {orderQuantity1350 * 1350} CK
                </Typography> : ""}
                  
                </CardContent>
              </Card>
{/*WINE*/}
              <Card>
                <CardHeader
                  title={
                    <Typography className='text-center shopFont2' component="h1" variant="" color="text.primary"><img className='storeKoin2' src={symbolBlack} alt="CK"/>1000</Typography>
                  }
                  /*subheader={<h5><b><img className='storeKoin2' src={symbolBlack} alt="CK"/>50 per Person</b></h5>}*/
                  titleTypographyProps={{ align: 'center' }}
                  /*action={'title'}*/
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[300]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Typography className="shopFont" variant="h5" color="text.secondary">
                  <>Wine or Sangria</>
                  </Typography>
                  <h5>*See menu for options</h5>
                  <ul>
                    <li className='MuiTypography-root MuiTypography-subtitle1 MuiTypography-alignCenter css-118o8hd-MuiTypography-root'>
                  <img className='text-center' src={wine} alt='CK'/>
                  <h5>1 Glass of Wine or Sangria</h5>
                    </li>
                  </ul>
                  <div className="form-group">
                  <label htmlFor="wine">
                  Quantity
                  </label>
                  <select className="form-control" onChange={handleOrderQuantity1000} id="wine" name="wine" required type="text">
                    <option></option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                  </select>
                  </div>

                {orderQuantity1000 > 0 ? <Typography className='text-center shopFont2' component="" variant="caption" >
                {orderQuantity1000 * 1000} CK
                </Typography> : ""}
                  
                </CardContent>
              </Card>
{/*NECKLACE*/}
              <Card>
                <CardHeader
                  title={
                    <Typography className='text-center shopFont2' component="h1" variant="" color="text.primary"><img className='storeKoin2' src={symbolBlack} alt="CK"/>550</Typography>
                  }
                  /*subheader={<h5><b><img className='storeKoin2' src={symbolBlack} alt="CK"/>50 per Person</b></h5>}*/
                  titleTypographyProps={{ align: 'center' }}
                  /*action={'title'}*/
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[300]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Typography className="shopFont" variant="h5" color="text.secondary">
                  <>Glow Necklace</>
                  </Typography>
                  <ul>
                    <li className='MuiTypography-root MuiTypography-subtitle1 MuiTypography-alignCenter css-118o8hd-MuiTypography-root'>
                  <img className='text-center' src={necklace} alt='CK'/>
                  <h5>8" Glow-in-the-Dark Necklace</h5>
                    </li>
                  </ul>
                  <div className="form-group">
                  <label htmlFor="necklace">
                  Quantity
                  </label>
                  <select className="form-control" onChange={handleOrderQuantity550} id="necklace" name="necklace" required type="text">
                    <option></option>
                    <option name="necklace" value="1">1</option>
                    <option name="necklace" value="2">2</option>
                    <option name="necklace" value="3">3</option>
                    <option name="necklace" value="4">4</option>
                    <option name="necklace" value="5">5</option>
                    <option name="necklace" value="6">6</option>
                    <option name="necklace" value="7">7</option>
                    <option name="necklace" value="8">8</option>
                  </select>
                  </div>

                {orderQuantity550 > 0 ? <Typography className='text-center shopFont2' component="" variant="caption" >
                {orderQuantity550 * 550} CK
                </Typography> : ""}
                  
                </CardContent>
              </Card>
{/*PUNCH*/}
              <Card>
                <CardHeader
                  title={
                    <Typography className='text-center shopFont2' component="h1" variant="" color="text.primary"><img className='storeKoin2' src={symbolBlack} alt="CK"/>500</Typography>
                  }
                  /*subheader={<h5><b><img className='storeKoin2' src={symbolBlack} alt="CK"/>50 per Person</b></h5>}*/
                  titleTypographyProps={{ align: 'center' }}
                  /*action={'title'}*/
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[300]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Typography className="shopFont" variant="h5" color="text.secondary">
                  <>Fruit Punch or Soda</>
                  </Typography>
                  <h5>*See menu for options</h5>
                  <ul>
                    <li className='MuiTypography-root MuiTypography-subtitle1 MuiTypography-alignCenter css-118o8hd-MuiTypography-root'>
                  <img className='text-center' src={punch} alt='CK'/>
                  <h5>1 Fresh Brewed Fruit Punch or Soda</h5>
                    </li>
                  </ul>
                  <div className="form-group">
                  <label htmlFor="punch">
                  Quantity
                  </label>
                  <select className="form-control" onChange={handleOrderQuantity500} id="punch" name="punch" required type="text">
                    <option></option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                  </select>
                  </div>

                {orderQuantity500 > 0 ? <Typography className='text-center shopFont2' component="" variant="caption" >
                {orderQuantity500 * 500} CK
                </Typography> : ""}
                  
                </CardContent>
              </Card>
{/*BRACELET*/}
              <Card>
                <CardHeader
                  title={
                    <Typography className='text-center shopFont2' component="h1" variant="" color="text.primary"><img className='storeKoin2' src={symbolBlack} alt="CK"/>250</Typography>
                  }
                  /*subheader={<h5><b><img className='storeKoin2' src={symbolBlack} alt="CK"/>50 per Person</b></h5>}*/
                  titleTypographyProps={{ align: 'center' }}
                  /*action={'title'}*/
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[300]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Typography className="shopFont" variant="h5" color="text.secondary">
                  <>Glow Bracelet</>
                  </Typography>
                  <ul>
                    <li className='MuiTypography-root MuiTypography-subtitle1 MuiTypography-alignCenter css-118o8hd-MuiTypography-root'>
                  <img src={bracelet} alt='CK'/>
                  <h5>8" Glow-in-the-Dark Bracelet</h5>
                    </li>
                  </ul>
                  <div className="form-group">
                  <label htmlFor="bracelet">
                  Quantity
                  </label>
                  <select className="form-control" onChange={handleOrderQuantity250} id="bracelet" name="bracelet" required type="text">
                    <option></option>
                    <option name="bracelet" value="1">1</option>
                    <option name="bracelet" value="2">2</option>
                    <option name="bracelet" value="3">3</option>
                    <option name="bracelet" value="4">4</option>
                    <option name="bracelet" value="5">5</option>
                    <option name="bracelet" value="6">6</option>
                    <option name="bracelet" value="7">7</option>
                    <option name="bracelet" value="8">8</option>
                  </select>
                  </div>

                {orderQuantity250 > 0 ? <Typography className='text-center shopFont2' component="" variant="caption" >
                {orderQuantity250 * 250} CK
                </Typography> : ""}
                  
                </CardContent>
              </Card>
{/*AFFILIATE*/}
              <Card>
                <CardHeader
                  title={
                    <Typography className='text-center shopFont2' component="h1" variant="" color="text.primary"><img className='storeKoin2' src={symbolBlack} alt="CK"/>0</Typography>
                  }
                  /*subheader={<h5><b><img className='storeKoin2' src={symbolBlack} alt="CK"/>50 per Person</b></h5>}*/
                  titleTypographyProps={{ align: 'center' }}
                  /*action={'title'}*/
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[300]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Typography className="shopFont" variant="h5" color="text.secondary">
                  <>Affiliate</>
                  </Typography>
                  <ul>
                    <li className='MuiTypography-root MuiTypography-subtitle1 MuiTypography-alignCenter css-118o8hd-MuiTypography-root'>
                  <h5>Become a Crypto Karaoke Affiliate</h5>
                  <h5>Get <img className='storeKoin2' src={symbol} alt="CK"/>150 for each Confirmed Referral</h5>
                    </li>
                  </ul>
                  <div className="form-group">
                  <label htmlFor="bracelet">Once Signed Up, add Referrals as Reservations</label>
                  <Button
                    //style={stickyButtonStyle}
                    className="shopFont"
                    fullWidth
                    variant='contained'
                    onClick={affiliateSignUp}
                    >SIGN UP NOW
                  </Button>
                  </div>
                  
                </CardContent>
              </Card>


      </Container>


      {/* End footer */}
    </>
  );
}

export default function Pricing() {
  return <PricingContent />;
}