//import { auth } from "../firebase";
//import { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import PerformerDataService from "../services/PerformerService";
import "./stage-style.css";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Key from '@mui/icons-material/VpnKey';
import Checkbox from '@mui/material/Checkbox';
//import * as React from 'react';

const Orders = (props) => {
 
  /* use react-firebase-hooks */
  const [transactions, loading, error] = useCollection(PerformerDataService.getOrders()); // asc or desc .orderBy("time", "desc")
  /////////////////

  /*const initialOrderState = {
    key: "",
    complete: false,
  };

  const [currentOrder, setCurrentOrder] = useState(false);*/
  
return (
<>
<div>

<h2 className='text-center'>Orders</h2>
  {error && <strong>Error: {error}</strong>}
  {loading && <span>Loading...</span>}     
  { !loading &&
  transactions &&
  transactions.docs.map((Transactions, index) => ( /* transactions.map */


<div className='container' key={index}>
{ Transactions.data() ? 


<List sx={{ width: '100%', maxWidth: 640 }}>
<ListItem alignItems="flex-start">
<List key={Transactions.data().code} sx={{ width: '100%', maxWidth: 360, bgcolor: '#ffc439', p:'0', mt: -1.5, mb: -1.5, }}>


<ListItem>
  <ListItemText key={Transactions.data().code+index} primary={<>( {Transactions.data().orderNumber} ) <Key/> {Transactions.data().code}
  <br/> 
  {Transactions.data().order.flight ? "Flight of Beer:" + Transactions.data().order.flight : ""}
  {Transactions.data().order.flight ? <br/> : ""}
  {Transactions.data().order.bubbleGun ? "Bubble Gun:" + Transactions.data().order.bubbleGun : ""}
  {Transactions.data().order.bubbleGun ? <br/> : ""}
  {Transactions.data().order.beer ? "Beer:" + Transactions.data().order.beer : ""} 
  {Transactions.data().order.beer ? <br/> : ""} 
  {Transactions.data().order.wine ? "Wine or Sangria:" + Transactions.data().order.wine : ""}
  {Transactions.data().order.wine ? <br/> : ""}
  {Transactions.data().order.punch ? "Punch or Soda:" + Transactions.data().order.punch : ""}
  {Transactions.data().order.punch ? <br/> : ""}
  {Transactions.data().order.necklace ? "Necklace:" + Transactions.data().order.necklace : ""}
  {Transactions.data().order.necklace ? <br/> : ""}
  {Transactions.data().order.bracelet ? "Bracelet:" + Transactions.data().order.bracelet : ""}
  {Transactions.data().order.bracelet ? <br/> : ""}

  ORDER COMPLETE
  <Checkbox
  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
/>
  </>}
  primaryTypographyProps={{
    fontSize: 15,
    fontWeight: 'medium',
    lineHeight: '20px',
    mb: '2px',
    color: '#003087',
  }}
  secondary={<>{Transactions.data().time}</>}
  secondaryTypographyProps={{
    noWrap: false,
    fontSize: 12,
    color: '#003087',
    lineHeight: '16px',
  }}
  sx={{ my: 0 }} />
</ListItem>

</List>
</ListItem>
</List>

: "" }

</div>
))}





</div>
</>
);
};
  
  export default Orders;