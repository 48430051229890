//import React, { useState } from 'react';
import { Link, useHistory /*Redirect, Route*/ } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { signInWithGoogle, /*FacebookAuthProvider, TwitterAuthProvider*/ } from "../helpers/auth";
import { auth } from "../firebase";
import UserService from "../services/UserService";
import logo from './SVG/Crypto-Karaoke-LOGO-Animated.svg'
//import Dialog from './Dialog';
import {
  //Paper,
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  //Slider
} from '@material-ui/core';


const SignUp = () => {

//const [startCoins] = useState(0);
//const [submitted, setSubmitted] = useState(false);

  const validationSchema = Yup.object().shape({
    /*fullname: Yup.string().required('Fullname is required'),
    username: Yup.string()
      .required('Username is required')
      .min(6, 'Username must be at least 6 characters')
      .max(20, 'Username must not exceed 20 characters'),*/
    email: Yup.string()
      .required('Email is required')
      .email('Email is invalid'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .max(40, 'Password must not exceed 40 characters'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
    acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required')
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  //For Redirect to Sign in
  const History = useHistory();


  const onSubmit = data => {
    console.log(JSON.stringify(data, null, 2));
    auth().createUserWithEmailAndPassword(data.email.toLowerCase(), data.password)
      .then((userCredential)=>{
          // send verification mail.
        userCredential.user.sendEmailVerification();
        auth().signOut();
        alert("Verification Email sent to: " + auth().currentUser.email);
        UserService.create(data.email.toLowerCase(), {email: data.email.toLowerCase(), affiliate: false, coins: 400, avatar: "https://robohash.org/" + data.email.toLowerCase()/* + "?set=set3"*/});
        //UserService.update(data.email.toLowerCase(), {coins: 0, avatar: "https://robohash.org/" + data.email.toLowerCase()/* + "?set=set3"*/});
        //Redirect to Sign in
        History.push('/signin');
      })
      .catch(alert);
    
    //signup(data.email, data.password);
    
    /*const sendEmail = () => {
  
        const url = "https://script.google.com/macros/s/AKfycbzavnEyAUFItIJiU794pgcE8Q1JsKUVggyXjfa7V34C16yXAVGFkx6s0xUPMHWbmaLT/exec";
        let d2 = new Date();
        //post Coins
        fetch(url,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors', // no-cors, *cors, same-origin 
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: 'omit', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({ timeStamp: d2.toLocaleString(), sender: data.email, coins: 0, performer: "none", phone: "none", message: "Check out all our live events on our website.", subject: "🎤 😁 WELCOME TO Crypto Karaoke!!!", title: "Thanks For Signing Up!" }) // body data type must match "Content-Type" header
          });
      };
      sendEmail();*/
  };

  function googleSignIn(){
    signInWithGoogle()
    .then((result) => {
      /** @type {auth().OAuthCredential} */
      //var credential = result.credential;
  
      // This gives you a Google Access Token. You can use it to access the Google API.
      // var token = credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      console.log(JSON.stringify(user, null, 2));
      UserService.create(user.email, { email: user.email.toLowerCase(), affiliate: false, coins: 400});
      // ...
    }).catch((error) => {
      // Handle Errors here.
      //var errorCode = error.code;
      //var errorMessage = error.message;
      // The email of the user's account used.
      //var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      //var credential = error.credential;
      // ...
    });
    
  };


  return (
    <>
    <div className='container'>
        <Box px={3} py={2}  className="form">
          <Typography variant="h6" align="center" margin="dense">
          <img src={logo} className="logo-smaller" alt="Crypto Karaoke®" /><br/>
            Sign Up
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                /*className="form-control"*/
                className="formFix"
                id="email"
                name="email"
                label="Email"
                fullWidth
                margin="dense"
                {...register('email')}
                error={errors.email ? true : false}
              />
              <Typography variant="inherit" color="textPrimary">
                {errors.email?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                /*className="form-control"*/
                className="formFix"
                id="password"
                name="password"
                label="Password"
                type="password"
                fullWidth
                margin="dense"
                {...register('password')}
                error={errors.password ? true : false}
              />
              <Typography variant="inherit" color="textPrimary">
                {errors.password?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                /*className="form-control"*/
                className="formFix"
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                fullWidth
                margin="dense"
                {...register('confirmPassword')}
                error={errors.confirmPassword ? true : false}
              />
              <Typography variant="inherit" color="textPrimary">
                {errors.confirmPassword?.message}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="acceptTerms"
                    defaultValue="false"
                    inputRef={register()}
                    render={({ field: { onChange } }) => (
                      <Checkbox
                        color="secondary"
                        onChange={e => onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={
                  <Typography color={errors.acceptTerms ? 'error' : 'inherit'}>
                    I have read and agree to the <Link to="/terms_of_service">Terms</Link> *
                  </Typography>
                }
              />
              <br />
              <Typography variant="inherit" color="textPrimary">
                {errors.acceptTerms
                  ? '(' + errors.acceptTerms.message + ')'
                  : ''}
              </Typography>
            </Grid>
          </Grid>

          <Box mt={3}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit(onSubmit)}
            >
              Register
            </Button>
            </Box>
            <br/>
            <Typography variant="h6" color="white">
              Or use one of these services:
            </Typography>
            <Box px={3} py={2}  className="form">
            <Grid container spacing={1}>
            
              <button className="btn btn-danger mr-2 form-control" type="button" onClick={() => googleSignIn()}>
                Google
              </button><hr/>
              {/*<button className="btn btn-danger mr-2 form-control" type="button" onClick={() => facebookSignIn()}>
                Facebook
              </button><hr/>
              <button className="btn btn-danger mr-2 form-control" type="button" onClick={() => twitterSignIn()}>
                Twitter
                </button>*/}
              </Grid>
              </Box>

            <Typography variant="h6" color="white">
            Have an account? <Link to="/signin">Sign in</Link>
            </Typography>
        </Box>
        </div>
    </>
  );
};

export default SignUp;