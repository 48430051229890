//import { collection } from "firebase/firestore";
import firebase from "../firebase";
//import { auth } from "../firebase";

const dbPerformers = firebase.collection("performers");
const dbLogs = firebase.collection("performanceLog");
const dbPerformanceLogNormal = firebase.collection("performanceLogNormal");
const dbUsers = firebase.collection("users");
const dbPrompts = firebase.collection("prompt");
const dbMessages = firebase.collection("messages");
const dbReservations = firebase.collection("reservations");
const dbOrders = firebase.collection("orders");

const getAll = () => {
  return dbPerformers;
};

/*const getAllTrans = () => {
  return dbTrans;
};*/

const getPrompts = (data) => {
  return dbPrompts.doc(data).collection("songURL");
};

const get = (data) => {
  return dbUsers.doc(data).collection("coins");
};

const getAvatar = (data) => {
  return dbUsers.doc(data).collection("avatar");
};

const getTransactions = (id) => {
  return firebase.collection("users/" + id + "/transactions");
};

const getOrders = () => {
  return firebase.collection("orders");
};

const getReservations = () => {
  return firebase.collection("reservations");
};

const getPerformances = (id) => {
  return firebase.collection("users/" + id + "/performances");
};

const createPrompt = (id, value) => {
  return dbPrompts.doc(id).update(value);
};

const createReservation = (data) => {
  return dbReservations.doc(data.doc).set(data);
};

const createOrder = (data) => {
  return dbOrders.doc(data.timePlaced).set(data);
};

const create = (data) => {
  return dbPerformers.doc(data.email).set(data);
};

const createLog = (data) => {
  return dbLogs.doc(data.timeRegistered).set(data);
};

const createLogNormal = (data) => {
  return dbPerformanceLogNormal.doc(data.email).set(data);
};

const createUserPerformaceLog = (data) => {
  return dbUsers.doc(data.email).collection("performances").doc(data.dt).set((data), { merge: true });
};

const sendSMS = (time, data) => {
  return dbMessages.doc(time).set(data);
};

const getAllPerformaceLogs = () => {
  return dbPerformanceLogNormal;
};

const update = (id, value) => {
  return dbPerformers.doc(id).update(value);
};

const updateReservation = (id, value) => {
  return dbReservations.doc(id).update(value);
};

const remove = (id) => {
  return dbPerformers.doc(id).delete();
};

const PerformerService = {
  get,
  getAvatar,
  getAll,
  getPrompts,
  getTransactions,
  getOrders,
  getReservations,
  getPerformances,
  create,
  createPrompt,
  createReservation,
  createOrder,
  createLog,
  createLogNormal,
  createUserPerformaceLog,
  sendSMS,
  getAllPerformaceLogs,
  update,
  updateReservation,
  remove
};

export default PerformerService;
