import * as React from 'react';
import { useHistory /*Redirect, Route*/ } from 'react-router-dom';
import { auth } from "../firebase";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import EmailIcon from '@mui/icons-material/Email';
import Link from '@mui/material/Link';

export default function AlertDialog() {
  const [open, setOpen] = React.useState(true);

  /*
  const handleClickOpen = () => {
    setOpen(true);
  };
  <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
  </Button>
  */

  const handleClose = () => {
    setOpen(false);
    History.push('/buy');
  };

  //For Redirect to Sign in
  const History = useHistory();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Verification Email Sent"}<hr/>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            VERIFY: <b>{auth().currentUser.email}</b> is yours.<br/><TouchAppIcon/>CLICK the <Link target='blank' href={'https://' + auth().currentUser.email.split('@')[1]}>Link</Link> in the email.<EmailIcon/><hr/>FROM: <b>noreply@crypto-karaoke.firebaseapp.com</b>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
