import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Privacy = () => {
    return (
<>
<Box sx={{ width: '100%', maxWidth: 1080 }} className="container">
<Typography variant="h4" gutterBottom component="div">
CRYPTO KARAOKE®
</Typography>
PRIVACY POLICY
<Typography variant="overline" display="block" gutterBottom>
(UPDATED: April 1, 2022)
</Typography>


<Typography variant="caption" display="block" gutterBottom>
This Privacy Policy ("Policy") explains what information Crypto Karaoke collects when you use our Services, as well as ways we might use or share it and your legal rights.
We are committed to protecting the privacy of our users.
This Policy doesn’t apply to practices of companies that we do not own or control, including other companies you might interact with on or through the Crypto Karaoke services.
</Typography>
<br/>
 
<Typography variant="subtitle2" display="block" gutterBottom>
Please also read the <Link to={"/terms_of_service"}>TERMS OF SERVICE</Link> which sets out the terms governing the Services.
</Typography>
<br/>

<Typography variant="subtitle2" display="block" gutterBottom>
When we say "Crypto Karaoke" (or "we", "us" and "our") we’re referring to Crypto Karaoke by TVM.Bio, Inc., including any of its subsidiaries. We’re a global company headquartered in Tampa. For the purposes of EU data protection law, Crypto Karaoke is a controller of personal data processed in the context of the Services. For California residents, please read the Privacy Notice for California Residents.
<br/><br/>
<Typography variant="caption" gutterBottom>
· We collect basic account information such as your email address, phone number, username and password. We need this information to create your account in order to provide you with access to our Services. This processing is necessary to provide our Service.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· We also obtain or infer demographic information. We do this based on our legitimate interest in providing you with a personalized experience on our Service.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· If you use Facebook or Google to register for or log in to the Services (or later connect your accounts), you authorize Facebook or Google, as the case may be, to share your personal information with us, including your email address, profile photo, birthday, and publicly available information about you and your Facebook friends, if you use Facebook for signup.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· Similarly, if you access the Services with another third party social network we will collect information that you have agreed to make available, such as your name, user ID/URL, profile picture, age, list of friends, email address and/or phone number, and/or your birthday, language selected, and/or geolocation. Third party social network data of this nature helps us pursue our legitimate interest in providing you with a personalized experience on our Service, for example for helping us to provide you with recommendations.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· We collect additional profile information about you when you upload a picture, or add your interests, music genre, skills and what you love. This enables us to provide recommendations and provide you with a personalized Service, in accordance with our legitimate interests.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· We have a legitimate interest in gathering information from the actions you take to recommend, create, communicate and share content so that we can work on improving our Service. This includes messages you send, information about the content you create yourself, the people you follow, the songs you mark as favorites or share, and the comments you post. In addition, we track social interactions in our Apps (e.g., what you listened to, what you loved, and your comments and invites).
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· We use depth based camera data (which may include facial expression mappings) such as those provided by Apple's TrueDepth Camera to create and control video and audio effects in your performances. Facial expression mappings from this data are only used in real time while your camera is active to adapt and synchronize filters and are never stored (on device or remotely), shared with third parties, or used for any purpose other than creating video and audio effects for your performance that you can choose to upload and store.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· If you send us support requests or comments you may also provide us with additional information to enable us to contact you and address your feedback and we may retain such information to enhance our Services and to respond to you.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· Forums, message boards, blogs and/or news groups may be available to you through the Services. Please remember that any information you post (including in these areas) may become public information and may be removed or addressed if in violation of our Terms of Service and Community Guidelines. We use publicly available information to help provide our Services. If you post personal information online that is accessible to the public, you may receive unsolicited responses. You should exercise caution when deciding to disclose any of your personal information in a public forum, including within our applications, as it may be misused or abused by third parties.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· When you purchase a subscription or any virtual coins or other items offered via the Services, you provide our payment processor with your payment information. This is necessary to process your payment. We currently use third parties to process such payments but may change that in the future.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· To be clear, we don’t ask you to provide us with any sensitive personal information, such as information relating to your race or ethnic origin, political opinions, religious or philosophical beliefs, membership of a trade union, physical or mental health, genetic or biometric information, sexual life or sexual orientation. However, if you decide to share this kind of information on Crypto Karaoke Services, you explicitly consent to us displaying it or sharing it in accordance with your selection.
</Typography><br/>
</Typography>

<Typography variant="h6" gutterBottom component="div">
1. From your browser and device
</Typography>

<Typography variant="caption" display="block" gutterBottom>
<Typography variant="caption" gutterBottom>
We collect information from your browser, computer, or mobile device, which provide us with technical information when you use the Services, including:
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· If you choose to find your friends on Crypto Karaoke, we will access your contacts on your device (including contact information your service provider(s) or app automatically added to your address book when you communicated with addresses or numbers not already in your list) and send your contacts to our servers to check if any of them are on the Services or the App you are using. If they are, we’ll suggest you follow them (but whether you do or not is totally up to you). Each time you open the contacts page in the Crypto Karaoke app we’ll automatically re-send the latest version of your contacts to our servers and provide you with an updated list of matches. We delete your contacts from our servers after we’ve checked for any matches.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· When you use the Services, we automatically collect certain log data, including your IP address, browser type, hardware type, unique device ID, the version of your operating system and your activity within the Services. We use log data for security purposes (such as preventing content from being improperly accessed) and to improve our Services (such as enhancing site performance in certain regions).
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· We receive device identifiers like Apple’s IDFA, IDFV and Google’s AdID. These are principally used for online advertising and marketing purposes.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· We access geo-location information from your mobile device. This helps us share where you are in the world, provide locations to your posts and content, and display local activities and content based on your current location. It also helps us to send you relevant messages based on where you live. You can control the collection of geo-location information through your device.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· We use cookies, which deposit small data files stored on your computer or mobile device, and other similar technologies like software development kits ("SDKs") or third party libraries.
We use cookies to distinguish you, identify your interests and your account and its status and settings, to collect usage data, keep track of your preferences and deliver content specific to your interests.
For example, we use cookies to remember your volume settings, language settings, search history, account type and the last time you checked or updated your feed.
Our advertising partners may use cookies and similar technologies to identify and deliver content and advertising specific to your interests, to track the effectiveness of such advertising and to provide and analyze their services.
When you first access our Services, we will ask you to consent to our use of cookies. We will offer you the choice to “Accept Cookies” or “Manage Cookies”.
If you choose to “Manage Cookies” you will be presented with a list of the different categories of cookies which we use, and you may select the purposes for which you consent to the use of cookies.
You can subsequently control your cookie choices via "Manage Cookies" link in Cookie Policy page. You may also control the use of cookies by selecting the appropriate settings in your browser.
However, please be aware that if you block cookies you won't have access to many features that may make your browsing of our website and use of our Services smoother, and some of our Services may not function properly.
</Typography><br/><br/>
</Typography>

<Typography variant="h6" gutterBottom component="div">
2. HOW WE USE YOUR INFORMATION
</Typography>

<Typography variant="subtitle2" display="block" gutterBottom>
We use the information we have to help us provide, operate, improve, understand, customize, support, and market our Services.
<Typography variant="caption" gutterBottom><br/><br/>
· In order to register for a Crypto Karaoke account, we need certain information about you.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· We may use your email address and/or phone number to send you information about the Services, provide customer support or to notify you of additional Crypto Karaoke products and services, where permitted by applicable law. You may use your account settings to unsubscribe from notifications from Crypto Karaoke.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· We may use information from one Crypto Karaoke App to improve your experience in other Apps: we seek to integrate our Services.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· We collect information to learn about your use of the Service and to enhance your social experience when using the Services, such as friend-to-friend interaction and notifications, chat, or messaging functionality, public leader boards, head-to-head competition and other similar features. We try to show you social content that we think will be of most interest to you.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· We try to continuously improve our Services and your experience. As part of this, we use the data we have to identify ways to make our Services better and more engaging for our users. We use your interactions on the Services, along with the other data described in this policy, to infer your interests. We do this to identify and suggest content that you may like and to tailor advertising, including off-site advertising.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· We use data to identify and respond to potential risks to our users and Services (e.g., spammers, phishing attempts, screen scraping, bullying and other violations of our Terms of Service and Community Guidelines).
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· In limited situations, we share de-identified content and information with researchers for educational research purposes.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· If you choose to provide us with your list of friends on a third party social network or a mobile device, your friends on such network can automatically follow you when you use the Services and your Crypto Karaoke account can be automatically configured to follow your friends. You can, however, unfollow any other user on Crypto Karaoke at any time.
<Typography variant="caption" gutterBottom>
· We promote our Services via customized advertising on third party sites. We may use your information to target advertising, in accordance with our legitimate interests; including to design advertising campaigns designed to reach individuals with similar profiles to existing Crypto Karaoke users.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· We use your information for customer relationship management (CRM) purposes, including for targeting email and other engagement campaigns
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· We sometimes conduct or sponsor surveys and contests. Participation in surveys and contests is completely voluntary. If you elect to participate, we may ask you for contact, demographic, and other information and may ask you to agree to additional terms and conditions.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· We use log data, such as device identifiers, to recognize you and / or your devices across different Crypto Karaoke applications. We do so in order to enhance your experience by providing you with seamless access to another Crypto Karaoke application by identifying you and making your content available on both applications.
</Typography>
</Typography>
</Typography><br/>

<Typography variant="h6" gutterBottom component="div">
3. INFORMATION YOU AND WE SHARE
</Typography>

<Typography variant="subtitle2" display="block" gutterBottom>
Crypto Karaoke is designed to help you share your performances around the world. Most of the information you provide us through your use of the Services is information you are asking us to make public. You share your information as you use and communicate through our Services, and we share your information to help us operate, provide, improve, understand, customize, support, and market our Services.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· Account Information. Your phone number, profile name and photo, bio, status, last seen status, and receipts may be available to anyone who uses our Services, although you can configure your settings to manage whether certain information is available to other users and businesses with whom you communicate.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· Your Content. Your content is published in accordance with your privacy settings. Content is public by default. For information about how to change your privacy settings with respect to content or how to delete content from the Services, see the frequently asked questions at www.Crypto Karaoke.com/support. If you comment on any content on the Services, your comments will be public and available to other users of the Services. In certain cases, you may choose to create recordings with other Crypto Karaoke users. In such cases, it is important to remember that you create the content together with another user or group of users as a collective. As an individual user within the collective, it is difficult if not impossible to undo the creation. So, if you create a collaboration with another Crypto Karaoke user, you won’t be able to change or delete their recorded performances included in that collaborative work. In addition, in such cases, or if you choose to make or contribute to a live performance, other users will be able to record and share that content.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· Your Contacts and Others. Users and businesses with whom you communicate may store or re-share your information (including your messages) with others on and off our Services.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· We may share information about you or your use of the Services with our advertisers and third party advertising partners. This means that, in accordance with our legitimate interests, we may give our advertisers information about the characteristics of our users, along with sharing certain identifiers and/or location information, but we do not specifically identify you by name or share your contact information. Third parties can use this data to deliver targeted advertisements that they believe will be of most interest to you, in order to enhance your experience on our Service, or to assess the effectiveness and reach of advertising campaigns.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· We work with Google Analytics which collects information such as how often users visit our website, what pages they visit when they do so, and what other sites they used prior to coming to the website. You may opt-out of Google Analytics tracking with the Google Analytics opt-out browser add-on.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· Third-Party Service Providers. We work with third-party service providers to help us operate, provide, improve, understand, customize, support, and market our Services. When we share your information with third-party service providers, we require them to use your information on our behalf in accordance with our instructions and terms.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· Any information in your public profile (e.g., your username and any profile image, location or additional information that you provide as part of your Public Profile, but not your phone number, or email address) will be accessible by other users of the Services. Content that you create through or upload to the Services will by default be public and available to users of the Services. For information about how to change your privacy settings with respect to content or how to delete content from the Services, see the frequently asked questions at www.CryptoKaraoke.com/support.
<Typography variant="caption" gutterBottom>
· We may publicly disclose aggregated statistics (like what musical genres are popular in a certain city).
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· We may use any recording, video and/or other content you create and post in the Services in order to promote Crypto Karaoke or our Services.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· We may share some or all of your information in connection with, or during negotiation of, any merger, financing, acquisition or dissolution, transaction or proceeding involving sale, transfer, divestiture, or disclosure of all or a portion of our business or assets. In the event of an insolvency, bankruptcy, or receivership, information may also be transferred as a business asset.
</Typography><br/><br/>
</Typography>

<Typography variant="h6" gutterBottom component="div">
4. HOW WE RESPOND TO LAW ENFORCEMENT REQUESTS
</Typography>

<Typography variant="caption" display="block" gutterBottom>
In certain circumstances, we may preserve and disclose your information to regulators, law enforcement, or others.
We will only do this if we believe in good faith that we are required to do so in connection with any legal investigation or legal complaint, or by law or regulation, including in response to a court order, subpoena, regulatory requirement or other legal demand or request or if we believe in good faith that it is reasonably necessary to protect the safety of any person, to address fraud, security or technical issues, to protect Crypto Karaoke's rights or property, and/or to investigate or assist in preventing any violation or potential violation of the law, this Privacy Policy, or our Terms of Service.
</Typography>

<Typography variant="h6" gutterBottom component="div">
5. OUR LEGAL BASES FOR COLLECTING, USING, SHARING AND OTHERWISE PROCESSING YOUR INFORMATION
</Typography>

<Typography variant="subtitle2" display="block" gutterBottom>
For the purposes of EU law, Crypto Karaoke relies on a number of legal bases to collect, use, share, and otherwise process the information we have about you for the purposes described in this Policy. These legal bases include:
</Typography>
<Typography variant="caption" gutterBottom>
· Certain processing is necessary to provide the Services in accordance with the Terms of Service. For example, we need to disseminate your content to provide our Service. We need basic contact information in order to register you for a Crypto Karaoke account and to share content on Crypto Karaoke, which is at the heart of our Service;
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· Where you have consented to certain processing. For example we may send you marketing messages if you have consented to receiving them, or we may ask you if you want to take part in voluntary surveys or contests. You may revoke that consent at any time;
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· For Crypto Karaoke to comply with a legal obligation, a court order, or to exercise and defend legal claims, to protect your vital interests or those of others, or where the processing is necessary in the public interest. For example, we may share data where we are required by law to do so; and
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· Some processing is necessary because it is in Crypto Karaoke’s or a third party’s legitimate interests, such as those of visitors, members or partners.
</Typography><br/><br/>
<Typography variant="subtitle1" gutterBottom>
Our legitimate interests
</Typography>
<Typography variant="subtitle2" gutterBottom>
We process your information in furtherance of the following legitimate interests:
</Typography><br/>
<Typography variant="caption" gutterBottom>
· Keeping the Services safe and secure: We use your information to try and keep our Services safe and secure. We do so as it is necessary to pursue our legitimate interests, the interests of our community and yours, in ensuring Crypto Karaoke is secure, and to protect against fraud, spam, misuse and abuse. For example, we process your information to protect you and Crypto Karaoke from malicious scraping of our website by those seeking to download music and recordings. We also want to protect against hackers who try to access content.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· Providing our Services: We use your information to provide the Services, including any personalized services. We do so as it is necessary to pursue our legitimate interests in providing an innovative and tailored offering to our users on a sustained basis and to provide stable connectivity. For example, where there is poor connectivity in an area or neighborhood, users can experience difficulty in downloading/uploading content. In that case, Crypto Karaoke needs to process log data, including your IP address, to troubleshoot the issue. Without your IP address and location info, it can be hard to fix connectivity issues.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· Learning how we can improve your experience on our Services, and contributing to research: We work hard to develop, enhance and improve our Services, such as by providing better song recommendations. We also share data with researchers to contribute to academic research. Where we contribute to academic research, we take into account your rights and ensure that protections are in place with respect to your information.
</Typography><br/><br/>

<Typography variant="h6" gutterBottom component="div">
6. MANAGING AND DELETING YOUR INFORMATION
</Typography>

<Typography variant="subtitle2" display="block" gutterBottom>
We offer the ability to correct or update the information collected during registration via your account settings on our website or the applicable App or by contacting support via our <Link to={{ pathname: "https://cryptokaraoke.live/contact-us" }} target="_blank"><Typography variant="button">ONLINE SUPPORT FORM</Typography></Link> and we rely on the accuracy of the information you provide us (such as your age). You may request deletion of your account by contacting support via our <Link to={{ pathname: "https://cryptokaraoke.live/contact-us" }} target="_blank"><Typography variant="button">ONLINE SUPPORT FORM</Typography></Link>.
</Typography><br/>
<Typography variant="caption" gutterBottom>
We retain your information in our server logs, our databases and our records only for as long as necessary in light of the purposes set out in this Policy, subject to any legal obligations to further retain such information. We may use third party service providers to store and maintain such data. We retain your verification-related information for a reasonable period of time, even if you do not complete all verification steps, to assist with future verifications and to prevent abuse of our verification processes. If you decide not to verify the account and would like us to delete the verification information you provided, contact support via our <Link to={{ pathname: "https://cryptokaraoke.live/contact-us" }} target="_blank"><Typography variant="button">ONLINE SUPPORT FORM</Typography></Link>.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
If you create content with other users their recorded performances included in such group content will remain after you delete your account.
</Typography><br/><br/>

<Typography variant="h6" gutterBottom component="div">
7. YOUR RIGHTS
</Typography>

<Typography variant="subtitle2" display="block" gutterBottom>
If you reside in certain territories, including the European Economic Area, you benefit from a number of rights in relation to your information. While some of these rights apply generally, certain rights apply only in certain limited cases. We describe these rights below in a summary format, but mandated applicable law shall govern.
</Typography><br/>
<Typography variant="caption" gutterBottom>
· Access and Porting. You can access much of your information by logging into your account. Where legally required, we can provide you with a copy of all of your information which we hold upon your request. Note that, in accordance with applicable law, information will not be provided where doing so would adversely affect the rights (including the intellectual property rights) of others.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· Change, Restrict, Limit, Delete. You can also change, restrict, limit or delete much of your information by logging into your account. If you cannot do this, or you do not have a Crypto Karaoke account, contact support via our <Link to={{ pathname: "https://cryptokaraoke.live/contact-us" }} target="_blank"><Typography variant="button">ONLINE SUPPORT FORM</Typography></Link>. We may retain certain information as required by applicable law.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· Object. If we process your information based on our legitimate interests explained above, or in the public interest, you can object to this processing in certain circumstances. In such cases, we will cease processing your information unless we have compelling legitimate grounds to continue processing or where it is needed for legal reasons. If you live in the European Economic Area, you also have a general right to object to the use of your information for direct marketing purposes.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· Revoke consent. Where you have previously provided your consent, you have the right to withdraw your consent to the processing of your information at any time. For example, you can withdraw your consent to email marketing by following the link provided in a marketing email. You can also revoke any consent you have provided to our use of your geo-location information by changing the setting on your mobile device. If you withdraw your consent to the use of your information for purposes set out in this Policy, you may not have access to all (or any) of our Services and we might not be able to provide you all (or any) of the Services. In certain cases, we may continue to process your information after you have withdrawn consent if we have a legal basis to do so or if your withdrawal of consent was limited to certain processing activities.
</Typography><br/><br/>
<Typography variant="caption" gutterBottom>
· Lodge a Complaint. If you reside in the European Economic Area, should you wish to raise a concern about our use of your information (and without prejudice to any other rights you may have), you have the right to do so with your local data protection authority. Additionally, we have appointed a Data Protection Office (“DPO”). To contact our DPO, please email admin @ CryptoKaraoke.app.
</Typography><br/><br/>

<Typography variant="h6" gutterBottom component="div">
8. SECURITY
</Typography>

<Typography variant="caption" display="block" gutterBottom>
We maintain technical and organizational measures to protect against accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to your information.
We endeavor to ensure a level of security appropriate to the risks that are presented by the processing, having all due regard to the state of the art, the costs of implementation and the nature, scope, context and purposes of the processing as well as the risk to users. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, while we use reasonable efforts to protect your personal information, we cannot guarantee its security.
</Typography><br/><br/>

<Typography variant="h6" gutterBottom component="div">
9. DATA TRANSFERS
</Typography>

<Typography variant="caption" display="block" gutterBottom>
When you use our Services your personal information is transferred to the United States, where the laws protecting privacy may be less stringent than those in your home country. We’re a US company, based in San Francisco, so this transfer is necessary for the performance of our Terms of Service. In certain cases, your data may be transferred to other countries or made available to users in other countries as part of the Services.
In certain cases, we may use a legal mechanism known as "standard contractual clauses" to protect information transferred outside the EU. Standard contractual clauses refer to contracts between companies transferring personal information that contain standard commitments approved by the EU Commission protecting the privacy and security of the information transferred. You can contact us to learn more and to request a copy of any clauses which may relate to your data.
</Typography><br/><br/>

<Typography variant="h6" gutterBottom component="div">
10. CHILDREN'S PRIVACY
</Typography>

<Typography variant="caption" display="block" gutterBottom>
Our Services are intended for general audiences, and are not intended for anyone under 13, anyone under 16 in the European Economic Area or anyone under the minimum age to use the Services in the jurisdiction where they reside ("Minimum Age"). Persons under the Minimum age are prohibited from using our Services and we do not knowingly collect information from such individuals. If we become aware that a child under the Minimum Age has provided us with their information, we take steps to remove such information and terminate the child's account, unless proper parental consent is delivered to Crypto Karaoke.
If you have concerns about a minor who you believe to be using our Services, please contact us using our <Link to={{ pathname: "https://cryptokaraoke.live/contact-us" }} target="_blank"><Typography variant="button">ONLINE SUPPORT FORM</Typography></Link>.
</Typography><br/><br/>

<Typography variant="h6" gutterBottom component="div">
11. CONTACT US
</Typography>

<Typography variant="caption" display="block" gutterBottom>
If you have any questions or comments or if you would like to exercise any statutory rights, please contact us via our <Link to={{ pathname: "https://cryptokaraoke.live/contact-us" }} target="_blank"><Typography variant="button">ONLINE SUPPORT FORM</Typography></Link>.
</Typography>

</Box>
<br/><br/>
</>
    );
}
export default Privacy;