import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import CardContent from '@material-ui/core/CardContent';
//import CardHeader from '@material-ui/core/CardHeader';
//import { useContainedCardHeaderStyles } from '@mui-treasury/styles/cardHeader/contained';
import { useSoftRiseShadowStyles } from '@mui-treasury/styles/shadow/softRise';
import { useCollection } from "react-firebase-hooks/firestore";
import PerformerDataService from "../services/PerformerService";
import Avatar from '@mui/material/Avatar';
//import symbol from "../logo.svg";
import symbol2 from "./SVG/CrytpoKaraokeLOGO.svg";
import crown from "./SVG/crown.svg";
//import award from "./SVG/award.svg";
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import "./stage-style.css";

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 14,
    height: 14,
    left: -12,
    top: 4,
    //border: `2px solid ${theme.palette.background.paper}`,
  }));

const useStyles = makeStyles(({ spacing }) => ({
  card: {
    marginTop: 40,
    paddingTop: 20,
    borderRadius: spacing(0),
    transition: '0.3s',
    width: '100%',
    overflow: 'initial',
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  },
  content: {
    paddingTop: 0,
    textAlign: 'left',
    overflowX: 'auto',
    '& table': {
      marginBottom: 0,
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "red"
        }
      }
    }
  }
}));


//let id = 0;

export const LeaderBoard = React.memo(function ElevatedHeaderCard() {
  const classes = useStyles();
  //const cardHeaderStyles = useContainedCardHeaderStyles();
  const cardShadowStyles = useSoftRiseShadowStyles({ inactive: false });
  //const cardHeaderShadowStyles = useFadedShadowStyles();
  /* use react-firebase-hooks */
  const [performaceLogs, loading, error] = useCollection(PerformerDataService.getAllPerformaceLogs().orderBy("coins", "desc")); // asc or desc
  //const [performaceLeader] = useCollection(PerformerDataService.getAllPerformaceLogs().orderBy("coins", "desc").limit(1)); // asc or desc
  return (
    <>
    <h3 className='text-center'>Leaderboard<br/><img src={crown} className='award' alt=''/></h3>
    
    {error && <strong>Error: {error}</strong>}
    {loading && <span>Loading...</span>}
    <Card className={cx(classes.card, cardShadowStyles.root)}>
 

      <CardContent className={classes.content}>
        <Table>       
          <TableBody>
          <>       
    { !loading &&
    performaceLogs &&
    performaceLogs.docs.slice(1,10).map((PerformaceLog) => ( /* performers.map */
                <TableRow key={PerformaceLog.data().email}>
                    <TableCell align="center"><>{PerformaceLog.data().avatar ? <img className='avatar' src={PerformaceLog.data().avatar} alt="CK"/> : ""}</><>{ PerformaceLog.data().stageName }</></TableCell>          
                    <TableCell align="center">{PerformaceLog.data().song}</TableCell>
                    <TableCell align="center">

                    <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    badgeContent={
                        <SmallAvatar alt="CK" src={symbol2} />
                    }
                    >
                    {PerformaceLog.data().coins}
                    </Badge>
   
                  </TableCell>
                </TableRow>
))}
</>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  </>);
});

export default LeaderBoard