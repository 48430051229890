import { auth } from "../firebase";


export function signup(email, password) {
    return auth().createUserWithEmailAndPassword(email, password);
}

export function isSignInWithEmailLink(email, password) {
    return auth().isSignInWithEmailLink(email, password);
}

export function signin(email, password) {
    return auth().signInWithEmailAndPassword(email, password);
}

export function signout() {
    return auth().signOut();
}

export function signInWithGoogle() {
    const provider = new auth.GoogleAuthProvider();
    return auth().signInWithPopup(provider);
}

export function FacebookAuthProvider() {
    const provider = new auth.FacebookAuthProvider();
    return auth().signInWithPopup(provider);
}

export function TwitterAuthProvider() {
    const provider = new auth.TwitterAuthProvider();
    return auth().signInWithPopup(provider);
}