import { Component } from "react";
import {
  //Route,
  //BrowserRouter as Router,
  //Switch,
  Redirect,
  Link
} from "react-router-dom";
import { signin, signInWithGoogle /*FacebookAuthProvider, TwitterAuthProvider*/ } from "../helpers/auth";
import { auth } from "../firebase";
//import UserService from "../services/UserService";
import logo from '../CK-LOGO-Animated.svg'
import '../App.css';
import {
  //Paper,
  Box,
  Grid,
  //TextField,
  Typography,
  //FormControlLabel,
  //Checkbox,
  Button,
  //Slider
} from '@material-ui/core';

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      email: "",
      password: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.googleSignIn = this.googleSignIn.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({
      error: "",
      email: this.state.email,
    });
    try {
      await signin(this.state.email, this.state.password);
      return (<Redirect to="/buy" />);
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  async googleSignIn() {
    try {
      await signInWithGoogle();
      this.setState({ email: auth().currentUser.email});
      //UserService.create(auth().currentUser.email, { email: auth().currentUser.email });
      const sendEmail = () => {
  
        const url = "https://script.google.com/macros/s/AKfycbzavnEyAUFItIJiU794pgcE8Q1JsKUVggyXjfa7V34C16yXAVGFkx6s0xUPMHWbmaLT/exec";
        let d2 = new Date();
        //post Coins
        fetch(url,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors', // no-cors, *cors, same-origin 
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: 'omit', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({ timeStamp: d2.toLocaleString(), sender: auth().currentUser.email, coins: "none", performer: "none", phone: "none", message: "Check out all our live events on our website.", subject: "🎤 😁 WELCOME BACK TO Crypto Karaoke!!!", title: "Let's Get This Party Started!" }) // body data type must match "Content-Type" header
          });
      };
      sendEmail();
      return (<Redirect to="/buy" />);

    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  /*async facebookSignIn() {
    try {
      await FacebookAuthProvider();
      this.setState({ email: auth().currentUser.email});
      //UserService.create(auth().currentUser.email, { email: auth().currentUser.email });
      const sendEmail = () => {
  
        const url = "https://script.google.com/macros/s/AKfycbzavnEyAUFItIJiU794pgcE8Q1JsKUVggyXjfa7V34C16yXAVGFkx6s0xUPMHWbmaLT/exec";
        let d2 = new Date();
        //post Coins
        fetch(url,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors', // no-cors, *cors, same-origin 
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: 'omit', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({ timeStamp: d2.toLocaleString(), sender: auth().currentUser.email, coins: "none", performer: "none", phone: "none", message: "Check out all our live events on our website.", subject: "🎤 😁 WELCOME BACK TO Crypto Karaoke!!!", title: "Let's Get This Party Started!" }) // body data type must match "Content-Type" header
          });
      };
      sendEmail();
      return (<Redirect to="/buy" />);

    } catch (error) {
      this.setState({ error: error.message });
    }
  } */

  /*async twitterSignIn() {
    try {
      await TwitterAuthProvider();
      this.setState({ email: auth().currentUser.email});
      //UserService.create(auth().currentUser.email, { email: auth().currentUser.email });
      const sendEmail = () => {
  
        const url = "https://script.google.com/macros/s/AKfycbzavnEyAUFItIJiU794pgcE8Q1JsKUVggyXjfa7V34C16yXAVGFkx6s0xUPMHWbmaLT/exec";
        let d2 = new Date();
        //post Coins
        fetch(url,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors', // no-cors, *cors, same-origin 
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: 'omit', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({ timeStamp: d2.toLocaleString(), sender: auth().currentUser.email, coins: "none", performer: "none", phone: "none", message: "Check out all our live events on our website.", subject: "🎤 😁 WELCOME BACK TO Crypto Karaoke!!!", title: "Let's Get This Party Started!" }) // body data type must match "Content-Type" header
          });
      };
      sendEmail();
      return (<Redirect to="/buy" />);

    } catch (error) {
      this.setState({ error: error.message });
    }
  } */



  render() {
    return (
      <div className="container text-center">
        <Typography variant="h6" align="center" margin="dense">
          <img src={logo} className="logo-smaller" alt="Crypto Karaoke®" /><br/>
            Sign In
          </Typography>
          <br/>
        <form
          className=""
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
            <div className="form-group">
            <input
              /*className="form-control"*/
              placeholder="Email"
              name="email"
              type="email"
              onChange={this.handleChange}
              value={this.state.email}
            />
          </div>
          <div className="form-group">
            <input
              /*className="form-control"*/
              placeholder="Password"
              name="password"
              onChange={this.handleChange}
              value={this.state.password}
              type="password"
            />
          </div>
          <div className="form-group">
            {this.state.error ? (
              <p className="text-danger">{this.state.error}</p>
            ) : null}
          <Button
              type="submit"
              variant="contained"
              color="secondary"
            >
              Sign in
            </Button>
          <Typography variant="h6" color="white">
              Or use one of these services:
            </Typography>
            <Box px={3} py={2}  className="form">
            <Grid container spacing={1}>
         
              <button className="btn btn-danger mr-2 form-control" type="button" onClick={this.googleSignIn}>
                Google
              </button><hr/>
              {/*<button className="btn btn-danger mr-2 form-control" type="button" onClick={this.facebookSignIn}>
                Facebook
              </button><hr/>
              <button className="btn btn-danger mr-2 form-control" type="button" onClick={this.twitterSignIn}>
                Twitter
              </button>*/}

              </Grid>
            </Box>
            
          </div>
          <Typography variant="h6" color="white">
            Don't have an account? <Link to="/signup">Sign up</Link>
            </Typography><br/><br/>
    
      
      
  
         
        </form>
      </div>
    );
  }
}