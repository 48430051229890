import { Component } from 'react';
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import logo from '../CK-LOGO-Animated.svg'
import '../App.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export default class HomePage extends Component {
  render() {
    return (
      <div className="home">
        <section>
          <div className="container jumbotron-fluid py-5">
            <div className="container text-center py-5">

            <div className="form-group" >
            <img src={logo} className="fixLogo" alt="Crypto Karaoke®" />
            </div>
              <p className="lead-turned-off">The World's Largest Karaoke Stage™</p>
              <div className="mt-4">
                <Box px={3} py={2}  className="">
                <Grid container spacing={0}>
                
        
                  <Link className="btn btn-danger mr-2 form-control"  to="/signup">Create New Account</Link>
                  <hr/>
                   <Link className="btn btn-danger mr-2 form-control"  to="/signin">Sign in</Link>
                  <hr/>
                  </Grid>
              </Box>
              </div><br/><br/>
              <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} columns={8}>
                <Grid item xs={4}>
                  <Link to={"/terms_of_service"} target="_blank"><Typography variant="button">Terms of Service</Typography></Link>
                </Grid>
                <Grid item xs={4}>
                  <Link to={"/privacy_policy"} target="_blank"><Typography variant="button">Privacy Policy</Typography></Link>
                </Grid>
              </Grid>
            </Box>
            </div>
          </div>
        </section>

      </div>
    )
  }
}