import { useState, /*, useRef */ } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import PerformerDataService from "../services/PerformerService";
import Performer from "./Perfomer";
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import SecurityIcon from '@mui/icons-material/Security';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';

const Admin = () => {
  // const [performers, setPerformers] = useState([]);
  const [currentPerformer, setCurrentPerformer] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const formatter = buildFormatter(0, 'minute');

  /* use react-firebase-hooks */
  const [performers, loading, error] = useCollection(PerformerDataService.getAll().orderBy("orderNumber", "asc")); // asc or desc

  /* manually listen for value events 
  const onDataChange = (items) => {
    let performers = [];

    items.docs.forEach((item) => {
      let id = item.id;
      let data = item.data();
      performers.push({
        id: id,
        stageName: data.stageName,
        song: data.song,
        published: data.published,
      });
    });

    setPerformers(performers);
  };

  useEffect(() => {
    const unsubscribe = PerformerDataService.getAll().orderBy("stageName", "asc").onSnapshot(onDataChange);

    return () => unsubscribe();
  }, []);
  */

  const refreshList = () => {
    setCurrentPerformer(null);
    setCurrentIndex(-1);
  };

  const setActivePerformer = (Performer, index) => {
    const { stageName, timeRegistered, song, songURL, phone, published, vip, orderNumber, waitNumber, coins, email, avatar } = Performer.data();

    setCurrentPerformer({
      id: Performer.id,
      timeRegistered,
      stageName,
      song,
      songURL,
      phone,
      published,
      vip,
      orderNumber,
      waitNumber,
      coins,
      email,
      avatar,
    });

    setCurrentIndex(index);
  };

  return (
    <>
    <div className="col-md-3">
    <h4>
      <SecurityIcon/>&nbsp;
      Admin:
    </h4>
    {/* <img src={auth().currentUser.photoURL ? auth().currentUser.photoURL : symbol} className="avatar" alt="C&#8214;K" id="symbol"/>
    <label className="signedIn" htmlFor="avatar">{auth().currentUser.email ? auth().currentUser.email : "Not Signed In"}</label> */}
    </div>
    {/*}<Increment/>*/}
    <div className="list row">
      <div className="col-md-6">
        <br/>
        <h4>
          <RecordVoiceOverIcon/>&nbsp;
          Performers:
        </h4>
        {error && <strong>Error: {error}</strong>}
        {loading && <span>Loading...</span>}
        <ul className="list-group">
          { !loading &&
            performers &&
            performers.docs.map((Performer, index) => ( /* performers.map */
              <li
                className={"list-group-item " + (index === currentIndex ? "active" : "")}
                onClick={() => setActivePerformer(Performer, index)}
                key={Performer.data().email+Performer.data().waitNumber}
              >
                { Performer.data().stageName }
                <br/><span>Wait: <TimeAgo date={Performer.data().waitNumber} formatter={formatter} /></span>
                { /*Performer.stageName*/ }
              </li>
            ))}
        </ul>
      </div>
      <div className="col-md-6">
      <div className="spacer-bottom"/>
        {currentPerformer ? (
          <Performer Performer={currentPerformer} refreshList={refreshList} />
        ) : (
          <div>
            <br />
            <p>Please click on a Performer...</p>
          </div>
        )}
      </div>
    </div>
    </>
  );
};

export default Admin;
