import React, { useState, useEffect } from "react";
import {
  Redirect,
  useHistory,
} from "react-router-dom";
import UserService from "../services/UserService";
import { auth, db } from "../firebase";
//import GooglePayButton from '@google-pay/button-react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import symbol from "../CK_Symbol.svg";
import symbolBlue from "../CK_Symbol-blue.svg";
import useSound  from "use-sound";
import ching from "./Sounds/CK_ching.mp3";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';


const style = {
  width: '100%',
  bgcolor: '#ffc439',
  color: '#003087',
  border: '3px solid #03a9f4',
  borderRadius: '0.25em',
  fontFamily: 'Oswald',  
};
/*
<GooglePayButton
              environment="TEST"
              buttonColor="white"
              buttonType="plain"
              buttonSizeMode="fill"
              paymentRequest={{
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: [
                  {
                    type: 'CARD',
                    parameters: {
                      allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                      allowedCardNetworks: ["AMEX", "DISCOVER", "JCB", "MASTERCARD", "VISA"],
                    },
                    tokenizationSpecification: {
                      type: 'PAYMENT_GATEWAY',
                      parameters: {
                        gateway: 'example',
                        gatewayMerchantId: 'exampleGatewayMerchantId',
                      },
                    },
                  },
                ],
                merchantInfo: {
                  merchantId: process.env.REACT_APP_GOOGLE_MERCHANT_ID,
                  merchantName: 'Crypto Karaoke®',
                },
                transactionInfo: {
                  totalPriceStatus: 'FINAL',
                  totalPriceLabel: 'Total',
                  totalPrice: buyCoinsPrice.toString(),
                  currencyCode: 'USD',
                  countryCode: 'US',
                },
              }}
              onLoadPaymentData={paymentRequest => {
                console.log('load payment data', paymentRequest);
                updateFanCoins();
                setSubmitted(true);
              }}
              style={{ width: '100%' }}
            />
*/
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="outlined" {...props} />;
});

const AddFanCoins = (props) => {

  /*
  const [amountKoins, setAmountKoins] = React.useState("");
  const handleChange = (event) => {
    setAmountKoins(event.target.value);
  };
  */

  //get current Koin count
  useEffect(() => {
    async function get(){
      const userRef = db.collection('users').doc(auth().currentUser.email);
      const doc = await userRef.get();
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        console.log('User Koins:', doc.data().coins);
        setFanCoins(doc.data().coins);
      }
    }
    get()
  }, [])

  
  const [fanCoins, setFanCoins] = useState(0);
  const [buyCoins, setBuyCoins] = useState(0);
  const [buyCoinsPrice, setBuyCoinsPrice] = useState(0);
  const [whichLevel, setWhichLevel] = useState("");
  const [submitted, setSubmitted] = useState(false);
  //const [message, setMessage] = useState("");

  const newFanCoins = () => {
    setSubmitted(false);
  };

  const setBuyButton = (amountMoney, amountCoins, level) => {
    setBuyCoinsPrice(amountMoney);
    setBuyCoins(amountCoins);
    setWhichLevel(level)
    console.log("Koins: " + buyCoins);
    console.log("Price: " + buyCoinsPrice);
    console.log("Level: " + whichLevel);
    handleChing(playbackRate + 0.1);
  };

  ////get ching set up
  const [playbackRate, setPlaybackRate] = React.useState(0.75);
  const [play] = useSound(ching, {
    playbackRate,
    volume: 0.5,
  });
  const handleChing = (value) => {
    setPlaybackRate(value);
    play();
    console.log("ching")
  };

  ///////
  const updateFanCoins = () => {
    let id = auth().currentUser.email;
    let coinsVar = { coins: fanCoins + buyCoins };
    UserService.update(id, coinsVar);
    setSubmitted(true);
  };

  const payAmount = buyCoinsPrice.toString();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  //For Redirect to Sign in 1,200 - Billboard  3,000 - Grammy  10,000 - Rock n Roll Hall of Fame
  const History = useHistory();
  let level_1A = "400"; 
  let level_1B = "Rising Star";
  let level_2A = "1,200"; 
  let level_2B = "Billboard";
  let level_3A = "3,000"; 
  let level_3B = "Grammy"; 
  let level_4A = "10,000"; 
  let level_4B = "Rock & Roll Hall of Fame"; 

  //validate form feilds

  return (
    <>
    {!submitted ?
    <>

    <div className="container">
    <div className="text-center">
      <div className="form-group text-center" >
      
      </div>

      {submitted ? (
        <div>
          <h4>You submitted successfully!</h4>
          <button className="btn btn-success" onClick={newFanCoins}>
            Add
          </button>
        </div>
      ) : (
        <div>
          <div className="container text-center">
          <div className="form-group">
          <h3>Buy Crypto Koins<span className='shopFontTM'>™</span></h3><hr/>
          <div>
          <Stack spacing={1} sx={{ width: '100%' }}>
          
          <div>{auth().currentUser.email ? auth().currentUser.email : "Not Signed In"}</div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success" variant="outlined" sx={{ width: '100%' }}>
              You Chose <span className="pre">{whichLevel} Level</span>
              </Alert>
            </Snackbar>
            <div><Alert severity="info" variant="outlined" >

              {!fanCoins ? <span className="pre">You Have: <img src={symbol} className="symbol" alt="C&#8214;K" />0</span>
          : <span className="pre">You Have: <img src={symbol} className="symbol" alt="C&#8214;K" />{fanCoins}</span>
          }
              
            </Alert></div>
          </Stack>
          </div>
          <Box sx={{ minWidth: 120 }}>
          <List sx={style} component="nav" aria-label="mailbox folders">
          <Divider>
        <Chip sx={style} label={<><TouchAppIcon/> CHOOSE YOUR LEVEL <TouchAppIcon/></>} />
      </Divider>
          <ListItem button>
            <ListItemText primary={<><img src={symbolBlue} className="symbol symbolBuyList" alt="C&#8214;K" /><>{level_4A + " - " + level_4B}</></>} value={10000} onClick={() => setBuyButton(49.99, 10000, level_4B)} onMouseUp={handleClick}/>
          </ListItem>
          <Divider />
          <ListItem button divider>
            <ListItemText primary={<><img src={symbolBlue} className="symbol" alt="C&#8214;K" /><>{level_3A + " - " + level_3B}</></>} value={3000} onClick={() => setBuyButton(19.99, 3000, level_3B)} onMouseUp={handleClick}/>
          </ListItem>
          <ListItem button>
            <ListItemText primary={<><img src={symbolBlue} className="symbol" alt="C&#8214;K" /><>{level_2A + " - " + level_2B}</></>} value={1200} onClick={() => setBuyButton(9.99, 1200, level_2B)} onMouseUp={handleClick}/>
          </ListItem>
          <Divider light />
          <ListItem button>
            <ListItemText primary={<><img src={symbolBlue} className="symbol" alt="C&#8214;K" /><>{level_1A + " - " + level_1B}</></>} value={400} onClick={() => setBuyButton(4.99, 400, level_1B)} onMouseUp={handleClick}/>
          </ListItem>
        </List>
      </Box>
      <hr/>
      {buyCoinsPrice > 0 ?
      <Alert severity="success" variant="outlined">
        <span className="pre">You Chose: <img src={symbol} className="symbol" alt="C&#8214;K" />{buyCoins}</span>
        <span className="pre"> - Price: ${buyCoinsPrice}</span>
      </Alert>
      : ""}
          </div>
          
            <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
            <PayPalButtons
                style={{ layout: "vertical" }}
                forceReRender={[payAmount]}
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: payAmount,
                                },
                            },
                        ],
                    });
                }}
                onApprove={(data, actions) => {
                    return actions.order.capture().then((details) => {
                        const name = details.payer.name.given_name;
                        updateFanCoins();
                        alert(`Transaction completed by ${name}`);
                        //Redirect to Sign in
                        History.push('/add');
                    });
                }}
            />
            </PayPalScriptProvider>
            </div>
            <div id="getInLine">
          </div>
        </div>
      )}
    </div>
    </div>
    </>
    : <Redirect to="/stage" />}
    </>
  );
};

export default AddFanCoins;
