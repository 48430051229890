import Grid from '@mui/material/Grid';
//import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
//import { createTheme } from '@material-ui/core/styles';
import { useCollection } from "react-firebase-hooks/firestore";
import PerformerDataService from "../services/PerformerService";
import "./stage-style.css";
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import sun from "./SVG/sun.svg";
import music from "./SVG/music-notes-jumping.svg";
//import coinDrop from "./SVG/coins-falling.svg";
import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
//import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import ElectricBoltIcon from '@mui/icons-material/Verified';



const formatter = buildFormatter(0, 'minute');

/*
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '60px',
}));
*/

//const darkTheme = createTheme({ palette: { mode: 'dark' } });

const Line = () => {
 
    /* use react-firebase-hooks */
    const [performers, loading, error] = useCollection(PerformerDataService.getAll().orderBy("orderNumber", "asc")); // asc or desc
    /////////////////

    //mui
    ///////
  
  
    return (
      
      <div className='wrapper'>
      <h2 className='text-center'>Wait List</h2>
      <div className='container'>
    {error && <strong>Error: {error}</strong>}
    {loading && <span>Loading...</span>}     
    { !loading &&
    performers &&
    performers.docs.map((Performer, index) => ( /* performers.map */         



<Grid
  key={index}
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center"
  className={ Performer.data().vip ? "waitingList" : "nonVIPList" }
>
      <List sx={{ width: '100%', maxWidth: 640 }}>
      <ListItem alignItems="flex-start" key={Performer.data().avatar}>
      <h6>{index ? index : <Avatar alt="" src={music} />}</h6>
      
      { Performer.data().vip ? 
        <ListItemAvatar key={Performer.data().email}>
        <Badge anchorOrigin={{vertical: "bottom", horizontal: "right", }} overlap="circular" badgeContent={<ElectricBoltIcon/>}> 
          <Avatar alt="" src={ Performer.data().avatar ? Performer.data().avatar : ""} />
        </Badge>
        </ListItemAvatar>
      : 
      <ListItemAvatar key={Performer.data().waitNumber}>
        <Avatar alt="" src={ Performer.data().avatar ? Performer.data().avatar : sun} />
      </ListItemAvatar>
      }
        
        <ListItemText key={Performer.data().timeRegistered}
          primary={<>{ Performer.data().stageName}<br/>{Performer.data().song }<br/>{<>Wait Time: <TimeAgo date={Performer.data().waitNumber} formatter={formatter} /></>}</>}
        />
      </ListItem>

    </List>
</Grid>


))}
</div>
</div>
);
};
  
  export default Line;