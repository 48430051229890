import { useState, /*, useRef */ } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import PerformerDataService from "../services/PerformerService";
import Reservations from "./Reservations";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import BadgeIcon from '@mui/icons-material/Badge';

const ReservationsList = () => {
  const [currentReservation, setCurrentReservation] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  /* use react-firebase-hooks */
  const [reservations, loading, error] = useCollection(PerformerDataService.getReservations().orderBy("timePlaced", "asc")); // asc or desc .orderBy("time", "desc")

  const refreshList = () => {
    setCurrentReservation(null);
    setCurrentIndex(-1);
  };

  const setActivePerformer = (Reservations, index) => {
    const { affiliate, doc, email, firstName, lastName, processed, sizeParty, timePlaced } = Reservations.data();

    setCurrentReservation({
      id: Reservations.id,
      affiliate,
      doc,
      email,
      firstName,
      lastName,
      processed,
      sizeParty,
      timePlaced
    });

    setCurrentIndex(index);
  };

  return (
    <>
    <div className="col-md-3">
    <h2 className='text-center'>Reservations</h2>
    </div>
    <div className="list row">
    <div className="col-md-6">
      
        {error && <strong>Error: {error}</strong>}
        {loading && <span>Loading...</span>}

        <ul className="list-group">
          { !loading &&
            reservations &&
            reservations.docs.map((Reservation, index) => ( /* Reservations.map */
              <List key={Reservation.data().timePlaced} sx={{ width: '100%', maxWidth: 640 }}>
              <ListItem alignItems="flex-start">
          { !Reservation.data().processed ?
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: '#1976d2', p:'0', mt: -1.5, mb: -1.5, }}>
          
                <li
                  className={"list-group-item " + (index === currentIndex ? "active" : "")}
                  onClick={() => setActivePerformer(Reservation, index)}
                  key={Reservation.data().email+Reservation.data().timePlaced}
                >
                  { Reservation.data().affiliate ? <h5><BadgeIcon/> AFFILIATE</h5> : "" }
                  { Reservation.data().firstName } { Reservation.data().lastName }
                  <br/>
                  { Reservation.data().email }
                  <hr/>
                  Guests: { Reservation.data().sizeParty }
                </li>
                
              </List>
            :
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: '#121212', p:'0', mt: -1.5, mb: -1.5, }}>
          
                <li
                  className={"list-group-item " + (index === currentIndex ? "active" : "")}
                  //onClick={() => setActivePerformer(Reservation, index)}
                  key={Reservation.data().email+Reservation.data().timePlaced}
                >
                  { Reservation.data().affiliate ? <h5><BadgeIcon/> AFFILIATE</h5> : "" }
                  { Reservation.data().firstName } { Reservation.data().lastName }
                  <br/>
                  { Reservation.data().email }
                  <hr/>
                  Guests: { Reservation.data().sizeParty }
                  <hr/>
                  PROCESSED
                </li>
                
              </List>
            }
              </ListItem>
              </List>
            )) }
        </ul>
    </div>
    
      <div className="col-md-6">
      <div className="spacer-bottom"/>
        {currentReservation ? (
          <Reservations Reservations={currentReservation} refreshList={refreshList} />
        ) : (
          <div>
            <br/><br/>
            <p>Click on a Reservation...</p>
          </div>
        )}
      </div>
    </div>

    </>
  );
};

export default ReservationsList;
