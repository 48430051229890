import * as React from 'react';
//import UserService from "../services/UserService";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
//import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
//import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import EventSeat from '@mui/icons-material/EventSeat';
//import BadgeIcon from '@mui/icons-material/Badge';
//import Badge from '@mui/material/Badge';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {Link} from "react-router-dom";
import { auth } from "../firebase";
//import symbol from "./SVG/logo-change.svg"
import SecurityIcon from '@mui/icons-material/Security';
//import Wysiwyg from '@mui/icons-material/Wysiwyg';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import RampLeft from '@mui/icons-material/RampLeft';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import ShoppingBag from '@mui/icons-material/ShoppingBag';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import PointOfSale from '@mui/icons-material/PointOfSale';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
//import Logout from '@mui/icons-material/Logout';
//import InfoIcon from '@mui/icons-material/Info';
//import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
//import MusicList from '@mui/icons-material/QueueMusic';
import "./nav-style.css";
import "../App.css";
//import ListAlt from '@mui/icons-material/ListAlt';

  const Reload = () =>  {window.location.reload()};
  const  signOut = () => {
    async function outRedirect() {
    await auth().signOut();
    Reload();
    }
    outRedirect();
  };

const settings = [
<a href="https://gestaltadvertising.com/crypto-karaoke/Crypto-Karaoke-Catalog.pdf" target="_blank" rel="noreferrer">Music Catalog</a>,
<Link to={"/performances"}>Performances</Link>,
<Link to={"/transactions"}>Transactions</Link>,
<Link to={"/signin"} onClick={() => signOut()}>Logout</Link>
];

const ResponsiveAppBar = () => {
  //const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  //For Redirect to Sign in
  //const History = useHistory();



  /*const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };*/
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  /*const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };*/

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#1976d2',
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
          </Typography>
          
          {/*<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <img src={symbol} className="symbol2" alt="C&#8214;K" />
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              
            </Menu>
          </Box>*/}
          {auth().currentUser.email === process.env.REACT_APP_ADMIN ?
          <><Link to={"/admin"}>
            <SecurityIcon/>
            </Link>
            <Link to={"/stage"} className="nav-link">
            <SettingsAccessibilityIcon/>
            </Link>
            <Link to={"/reservations"}>
            <EventSeat/>
            </Link>
            <Link to={"/orders"} className="nav-link">
            <PointOfSale/>
            </Link>
          </>
            :
            <>
            <Link to={"/leader"} className="nav-link2">
            <LeaderboardIcon/>
            </Link>
            <Link to={"/stage"} className="nav-link2">
            <SettingsAccessibilityIcon/>
            </Link>
            <Link to={"/line"} className="nav-link2">
            <FormatListNumberedIcon/>
            </Link>
            <Link to={"/add"} className="nav-link2">
            <RampLeft/>
            </Link>
            
            </>
            }
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            
          </Box>
          {!auth().currentUser.email === process.env.REACT_APP_ADMIN ?
          <>
          </>
            :
            <>
            <Link to={"/shop"}>
            <ShoppingBag/>
            </Link>
            <Link to={"/buy"} className="nav-link">
            <ShoppingCart/>
            </Link>
          </>
            }
          
          <Box sx={{ flexGrow: 0 }}>

          {/*auth().currentUser.email === process.env.REACT_APP_ADMIN ?
          <Badge anchorOrigin={{vertical: 'bottom', horizontal: 'right', }} overlap="circular" badgeContent={<BadgeIcon/>}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="" src={auth().currentUser.photoURL ? auth().currentUser.photoURL : ""} />
              </IconButton>
            </Tooltip>
            </Badge>
            : 
            ""
          */}
            {auth().currentUser.photoURL ? 
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="" src={auth().currentUser.photoURL ? auth().currentUser.photoURL : ""} />
              </IconButton>
            </Tooltip>
            : 
            <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="" src={"https://robohash.org/" + auth().currentUser.email} />
            </IconButton>
            </Tooltip>
            }

            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting, index) => (
                <MenuItem key={index} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    </ThemeProvider>
  );
};
export default ResponsiveAppBar;
