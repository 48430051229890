import firebase, { auth } from "../firebase";

const db = firebase.collection("/users");
//const usersDB = firebase.collection("users");
//let fbDocName = auth().currentUser.email;
//const usersDocName = "repertrium@gmail.com";

//const userDoc = usersDB.doc("/" + usersDocName).collection('coins');


const dbTransactions = firebase.collection('users');

const getTransactions = () => {
  return dbTransactions;
};

/*
async function getTransactions() {
  const userRef = firebase.collection('users').doc(auth().currentUser.email);
  const doc = await userRef.get();
  if (!doc.exists) {
    console.log('No such document!');
  } else {
    console.log('Document data:', doc.data().coins);
    return doc.data().coins;
  }
}
*/

async function get() {
  const userRef = firebase.collection('users').doc(auth().currentUser.email);
  const doc = await userRef.get();
  if (!doc.exists) {
    console.log('No such document!');
  } else {
    console.log('Document data:', doc.data().coins);
    return doc.data().coins;
  }
}

async function get2(id) {
  const userRef = firebase.collection('users').doc(id);
  const doc = await userRef.get();
  if (!doc.exists) {
    console.log('No such document!');
  } else {
    console.log('Document data:', doc.data().coins);
    return doc.data().coins;
  }
}


const create = (id, data) => {
  //return db.add(data);
  return firebase.collection("users").doc(id).set(data);
};

const update = (id, value) => {
  return firebase.collection("users").doc(id).update(value);
};

const updateEarnedCoins = (id, value) => {
  return firebase.collection("users").doc(id).update(value);
};


const remove = (id) => {
  return db.doc(id).delete();
};

const UserService = {
  get,
  get2,
  getTransactions,
  create,
  update,
  updateEarnedCoins,
  remove
};

export default UserService;