import { auth } from "../firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import PerformerDataService from "../services/PerformerService";
import "./stage-style.css";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import symbol from "../CK_Symbol.svg";
import SendIcon from '@mui/icons-material/Send';
import Key from '@mui/icons-material/VpnKey';


const Transactions = () => {
 
    /* use react-firebase-hooks */
    const [transactions, loading, error] = useCollection(PerformerDataService.getTransactions(auth().currentUser.email).orderBy("time", "desc")); // asc or desc 
    /////////////////
  
    return (   
<div>
<h2 className='text-center'>Transactions</h2>
{error && <strong>Error: {error}</strong>}
{loading && <span>Loading...</span>}     
{ !loading &&
transactions &&
transactions.docs.map((Transactions, index) => ( /* transactions.map */

<div className='' key={Transactions.data().time}>
{ Transactions.data() ? 


      <List sx={{ width: '100%', maxWidth: 640 }}>
      <ListItem alignItems="flex-start">
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: '#ffc439', p:'0', mt: -1.5, mb: -1.5, }}>
      
      <ListItem>

        <ListItemText primary={<><img src={symbol} className="symbol" alt="C&#8214;K" />{Transactions.data().coins}<SendIcon/> {Transactions.data().rewarded} {Transactions.data().rewarded === "Order" ? <>(# {Transactions.data().transactionOrderNumber})</> : ""} {Transactions.data().code ? <><br/><Key/> {Transactions.data().code}</> : ""}
        </>}
                  primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: 'medium',
                    lineHeight: '20px',
                    mb: '2px',
                    color: '#003087',
                  }}
                  secondary={<>{Transactions.data().time}</>}
                  secondaryTypographyProps={{
                    noWrap: false,
                    fontSize: 12,
                    color: '#003087',
                    lineHeight: '16px',
                  }}
                  sx={{ my: 0 }} />
      </ListItem>
      </List>
      </ListItem>
      </List>

: "" }
</div>

))}
</div>

);
};
  
  export default Transactions;