import { useState } from "react";
import PerformerDataService from "../services/PerformerService";
//import { Redirect } from "react-router-dom";
import { useHistory /* Link, Redirect, Route*/ } from 'react-router-dom';
/* THIS MAKES INCREMENT COUNTER WORK */
import firebase from 'firebase/compat/app';
import { db } from "../firebase";
/* THIS MAKES INCREMENT COUNTER WORK */
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import BadgeIcon from '@mui/icons-material/Badge';

const Reservations = (props) => {
  const initialReservationState = {
    //key: "",
    affiliate: "",
    doc: "",
    email: "",
    firstName: "",
    lastName: "",
    processed: false,
    sizeParty: 0,
    timePlaced: ""
  };

  const [currentReservation, setCurrentPerformer] = useState(initialReservationState);
  const [message, setMessage] = useState("");

  const { Reservations } = props;
  if (currentReservation.id !== Reservations.id) {
    setCurrentPerformer(Reservations);
    setMessage("");
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentPerformer({ ...currentReservation, [name]: value });
  };
 
  const updatePersonCount = () => {
    const data = {
      sizeParty: currentReservation.sizeParty
    };

    PerformerDataService.updateReservation(currentReservation.doc, data)
      .catch((e) => {
        console.log(e);
      });
  };

  //For Redirect to Transactions
  const History = useHistory();

  const updatePublished = () => {

    //save Earned Koins to User Account
    
    //////BATCH WRITE TRANSACTION TO FIREBASE
      console.log('STARTED COUNTER');
      var dt = new Date();
      var utcDate = dt.toString();
      var date = dt.toLocaleDateString();
      var time = dt.toLocaleTimeString();
      var dateTime = date + " - " + time;
      var amount = currentReservation.sizeParty * 150;
  
      const batch = db.batch();
      const reservationRef = db.collection('reservations').doc(currentReservation.doc);
      const userRef = db.collection('users').doc(currentReservation.email);
      const transactionRef = db.collection('users').doc(currentReservation.email).collection('transactions').doc(utcDate);
      const increment = firebase.firestore.FieldValue.increment(amount);
      //const decrement = firebase.firestore.FieldValue.increment(-amount);
            
      batch.set(reservationRef, { processed: true }, { merge: true });
      //console.log('PERFORMER SET');
      batch.set(userRef, { coins: increment }, { merge: true });
      batch.set(transactionRef, { coins: amount, rewarded: 'You Earned For Confirmed Affiliate Reservation', time: dateTime });
      console.log('USER SET');
      batch.commit();
      console.log('FINISHED COUNTER');

    ////////////////////////////////////////////
    //Send to Transactions Page
    History.push(window.location.reload());
};

  const completeReservation = () => {
      //Process Reservations
      /*const data = {
        processed: true
      };*/
  
      PerformerDataService.updateReservation(currentReservation.doc, { processed: true })
        .catch((e) => {
          console.log(e);
        });
        
      //Send to Transactions Page
      //History.push('/transactions');
  };

  return (
    <div>
      {currentReservation ? (
        <div>
          <br/><br/>
          <h2 className='text-center'>Selected</h2>
 
          <List sx={{ width: '100%', maxWidth: 640 }}>
          <ListItem alignItems="flex-start">
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: '#ffc439', color: '#003087', padding: 2.5, mt: 0, mb: 0, }}>
          <li
              className={""}
              //onClick={() => setActivePerformer(Reservation, index)}
              key={currentReservation.email+currentReservation.timePlaced}
            >
              
              {currentReservation.processed ? "PROCESSED" : ""}{ currentReservation.affiliate ? <h5><BadgeIcon/> AFFILIATE</h5> : "" }
              { currentReservation.firstName } { currentReservation.lastName }
              <br/>
              { currentReservation.email }
              <hr/>
              Guests: 
              <input
              type="text"
              /*className="form-control"*/
              id="sizeParty"
              name="sizeParty"
              value={currentReservation.sizeParty}
              onChange={handleInputChange}
            />
          </li>
          <br/><br/>
              {currentReservation.affiliate ?
              <>
              <button
              type="submit"
              className="btn btn-group"
              onClick={() => updatePersonCount()}
              >
                Update
              </button>
              <button
              className="btn btn-group"
              type="submit"
              onClick={() => updatePublished()}
              >
                Reward Koins
              </button>
              </>
            :
            <button
            type="submit"
            className="btn btn-group"
            onClick={() => completeReservation()}
            >
              Seated
            </button>
              }
          </List>
          </ListItem>
          </List>


          <p>{message}</p>
        </div>
      ) : (
        <div>
          <br />
          <p>Please click on a Reservation...</p>
        </div>
      )}
    </div>
  );
};

export default Reservations;
