import { auth } from "../firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import PerformerDataService from "../services/PerformerService";
import "./stage-style.css";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import symbol from "../CK_Symbol.svg";

const Performances = () => {
 
    /* use react-firebase-hooks */
    const [transactions, loading, error] = useCollection(PerformerDataService.getPerformances(auth().currentUser.email).orderBy("dt", "desc")); // asc or desc 
    /////////////////
  
    return (
      <>
      
      <div>
      <h2 className='text-center'>Performances</h2>
    {error && <strong>Error: {error}</strong>}
    {loading && <span>Loading...</span>}     
    { !loading &&
    transactions &&
    transactions.docs.map((Transactions) => ( /* transactions.map */
          


<div className='' key={Transactions.data().time+Transactions.data().timeEnded+Transactions.data().stageName}>
{ Transactions.data() ? 


      <List key={Transactions.data().time} sx={{ width: '100%', maxWidth: 640 }}>
      <ListItem key={Transactions.data().time+Transactions.data().timeEnded} alignItems="flex-start">
      <List key={Transactions.data().timeEnded} sx={{ width: '100%', maxWidth: 360, bgcolor: '#ffc439', p:'0', mt: -1.5, mb: -1.5, }}>
      
      <ListItem>

        <ListItemText key={Transactions.data().timeEnded+Transactions.data().stageName} primary={<>Stagename: {Transactions.data().stageName} <br/>Song: {Transactions.data().song}
        <br/>Started at: {Transactions.data().timeEnded}<br/>Received: <img src={symbol} className="symbol" alt="C&#8214;K" /> {Transactions.data().coins}</>}
                  primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: 'medium',
                    lineHeight: '20px',
                    mb: '2px',
                    color: '#003087',
                  }}
                  secondary={<>{Transactions.data().time}</>}
                  secondaryTypographyProps={{
                    noWrap: false,
                    fontSize: 12,
                    color: '#003087',
                    lineHeight: '16px',
                  }}
                  sx={{ my: 0 }} />
      </ListItem>

    </List>
      
            

      </ListItem>

    </List>

: "" }
</div>


))}
</div>
</>
);
};
  
  export default Performances;