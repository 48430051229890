import { useState, useEffect } from "react";
import {
  Redirect,
  Link,
} from "react-router-dom";
import PerformerDataService from "../services/PerformerService";
import UserService from "../services/UserService";
/* THIS MAKES INCREMENT COUNTER WORK */
//import firebase from 'firebase/compat/app';
import { db, auth } from "../firebase";
/* THIS MAKES INCREMENT COUNTER WORK */
import symbol from "../CK_Symbol.svg";
import MusicNote from '@mui/icons-material/MusicNote';
import PhoneEnabled from '@mui/icons-material/PhoneEnabled';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import RampLeft from '@mui/icons-material/RampLeft';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import './add-performer.css';
import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TouchAppIcon from '@mui/icons-material/TouchApp';




const AddPerformer = () => {

  //check if client is already in line
  useEffect(() => {
    async function getInLine(){
      const userRef = db.collection('performers').doc(auth().currentUser.email);
      const doc = await userRef.get();
      if (!doc.exists) {
        console.log('Not Already in Line');
        setInLine(false);
      } else {
        console.log('Already in Line');
        setInLine(true);
      }
    }
    getInLine()
  }, [])

  //get current Koin count
  useEffect(() => {
    async function getFanCoins(){
      const userRef = db.collection('users').doc(auth().currentUser.email);
      const doc = await userRef.get();
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        console.log('Document data:', doc.data().coins);
        setFanCoins(doc.data().coins);
      }
    }
    getFanCoins()
  }, [])


  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  

    const [open, setOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    //const [chooseAvatar, setChooseAvatar] = React.useState('');
    
  
    const handleClick = () => {
      setOpen(true);
    };
  
    const handleClose2 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    function isPicAvail(){
      if(auth().currentUser.photoURL) {
        return auth().currentUser.photoURL;
      }else{
         return "https://robohash.org/" + auth().currentUser.email;
      }
    };
    ;
  

  const initialPerformerState = {
    timeRegistered: null,
    email: auth().currentUser.email,
    avatar: isPicAvail(),
    orderNumber: null, 
    waitNumber: null, 
    vip: false,
    phone: '',
    stageName: "",
    song: "",
    songURL: "",
    published: false,
    coins: 0,
  };
  const payCoins = 1500;
  const [userCoins, setFanCoins] = useState(0);
  const [performer, setPerformer] = useState(initialPerformerState);
  const [submitted, setSubmitted] = useState(false);
  const [inLine, setInLine] = useState(false);


  const handleInputChange = event => {
    const { name, value } = event.target;
    setPerformer({ ...performer, [name]: value });
    console.log({ value });
  };

  const handleInputClick = event => {
    //const songUnfixed = song + '〚' + artist + '〛';
    //const songFixed = songUnfixed.replace(/\s/g, '⊕')
    
    //setPerformer({ ...performer, song: songUnfixed, songURL: 'https://gestaltadvertising.com/crypto-karaoke/vids/' + songFixed + '.mp4'});
    setPerformer({ ...performer, song: song});
    //setPerformer({ ...performer, songURL: 'https://gestaltadvertising.com/crypto-karaoke/vids/' + song + '〚' + artist + '〛.mp4'});
    setChosenSong(!songChosen);
    //console.log(song + '〚' + artist + '〛');
  };



  const savePerformer = () => {
    if (!performer.stageName){
      setErrorMessage("Choose a Stage Name");
      handleClick();
      //alert("Choose a Stage Name");
    }else{
    if (!performer.song){
      setErrorMessage("Choose a Song");
      handleClick();
      //alert("Choose a Song");
    }else{
      if (!performer.phone){
        setErrorMessage("Add your Cell #");
        handleClick();
        //alert("Add your Cell #");
      }
    }
    }
    if (performer.stageName && performer.song && performer.phone) {
    let d2 = new Date();
    let d = new Date();
    let time = d.toLocaleString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    let month = d.getMonth() + 1;	// Month	[mm]	(1 - 12)
    let day = d.getDate();		// Day		[dd]	(1 - 31)
    //let year = d.getFullYear();	// Year		[yyyy]
    var data = {
      timeRegistered: month + "/" + day + "/" + time,
      email: auth().currentUser.email,
      avatar: initialPerformerState.avatar,
      orderNumber: d.toLocaleString(),
      waitNumber: d2.toLocaleString(),
      vip: false,
      phone: performer.phone,
      stageName: performer.stageName,
      song: performer.song,
      songURL: performer.songURL,
      published: false,
      coins: performer.coins,
    };

    PerformerDataService.create(data)
      .then(() => {
        setSubmitted(true);
        //alert("You are aleady in line. You will be redirected to the line queue.");
        UserService.update(auth().currentUser.email, { phone: performer.phone }, { merge: true });
      })
      .catch(e => {
        console.log(e);
      });
    }
  };

  const savePerformerVIP = () => {
    if (!performer.stageName){
      setErrorMessage("Choose a Stage Name");
      handleClick();
      //alert("Choose a Stage Name");
    }else{
    if (!performer.song){
      setErrorMessage("Choose a Song");
      handleClick();
      //alert("Choose a Song");
    }else{
      if (!performer.phone){
        setErrorMessage("Add your Cell #");
        handleClick();
        //alert("Add your Cell #");
      }
    }
    }
    if (performer.stageName && performer.song && performer.phone) {
    var d = new Date();
        d.setMonth(d.getMonth() - 1);
    //let d = new Date() - 10000000;
    let d2 = new Date();
    let d3 = new Date();
    let time = d2.toLocaleString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    let month = d2.getMonth() + 1;	// Month	[mm]	(1 - 12)
    let day = d2.getDate();		// Day		[dd]	(1 - 31)
    let year = d.getFullYear();	// Year		[yyyy]
    var data = {
      timeRegistered: month + "/" + day + "/" + time,
      email: auth().currentUser.email,
      avatar: initialPerformerState.avatar,
      orderNumber: d.toLocaleString(), 
      waitNumber: d3.toLocaleString(),
      vip: true,
      phone: performer.phone,
      stageName: performer.stageName,
      song: performer.song,
      songURL: performer.songURL,
      published: false,
      coins: performer.coins,
    };

    //////BATCH WRITE ORDER TRANSACTION TO FIREBASE

    console.log('STARTED ORDER');
    var dt = new Date();
    var utcDate = dt.toString();
    var dateTime = month + "/" + day + "/" + year + " - " + time;

    const batch = db.batch();
    const transactionRef = db.collection('users').doc(auth().currentUser.email).collection('transactions').doc(utcDate);
    const userRef = db.collection('users').doc(auth().currentUser.email);
    batch.set(userRef, { coins: userCoins - payCoins, phone: performer.phone }, { merge: true });
    batch.set(transactionRef, { coins: payCoins, rewarded: "VIP Line", time: dateTime });
    

    ////////////////////////////////////////////

  
    PerformerDataService.create(data)
      .then(() => {
        setSubmitted(true);
        batch.commit();
    //PerformerDataService.createPrompt('setter', { songURL: data.songURL, song: data.song })     
      })
      .catch(e => {
        console.log(e);
        //setErrorMessage("Already in Line");
        //handleClick();
        //alert("Already in Line")
      });
    }

  };
  

  /*const newPerformer = () => {
    setPerformer(initialPerformerState);
    setSubmitted(false);
  };*/

  const [value, setValue] = useState('');
  const [song, setSong] = useState([]);
  //const [artist, setArtist] = useState('');
  const [songChosen, setChosenSong] = useState(false);


  const handleUnChooseSong = () => {
    setSong([]);
    //setArtist('');
    setChosenSong(!songChosen);
    setPerformer({ ...performer, song: ''});
  };
  


    useEffect(() => {
        if(value.length > 4){
            fetch('https://crypto-karaoke-default-rtdb.firebaseio.com/music.json').then(
                response => response.json()
            ).then(responseData => {
                setSong([]);
                //setArtist('');
                let searchQuery = value.toLowerCase();
                for(const key in responseData) {
                    let fruit = responseData[key].song.toLowerCase();
                    if(fruit.slice(0, searchQuery.length).indexOf(searchQuery) !== -1) {
                        /*setArtist(prevResult => {
                            return [...prevResult, responseData[key].artist];

                        })*/
                        setSong(prevResult => {
                            return [...prevResult, responseData[key].song];

                        })

                    }
                }
            }).catch(error => {
                console.log(error);
            })
        }else{
            setSong([]);
            //setArtist('');
        }

    }, [value, setChosenSong])


  //validate form feilds
  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
  
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");
  
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
  
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
    if (phoneNumberLength < 4) return phoneNumber;
  
    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
  
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const [inputValue, setInputValue] = useState("");

  const handleInput = (e) => {
    // this is where we'll call the phoneNumberFormatter function
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setInputValue
    setInputValue(formattedPhoneNumber);
    setPerformer({ ...performer, phone: formattedPhoneNumber });
    console.log({ inputValue });
  };
  

  return (
    <>
    <div className="container">
    <div className="form-group">
    <div className="text-center">
      <div className="form-group" >
      {auth().currentUser.photoURL ?
      <img src={auth().currentUser.photoURL} className="avatar-lg" alt="Crypto Karaoke®" />
      :
      <>
      <hr/>
      {/*<img src={symbolAnimated} className="makeLogoSmaller" alt="Crypto Karaoke®" />*/}
      </>
      }
      {submitted ? <Redirect to="/stage" /> : ""}   
      </div>
      {inLine ? (
        <div>
          {alert("You are already in Line. Let's go to see.")}
          <Redirect to="/stage" />
        </div>
        
      ) : (
        <div>
          <h3>Get in Line</h3>
          <hr/><br/>
          <div className="form-group">
            <label htmlFor="stageName">
            <SettingsAccessibilityIcon/>
              Stage Name (Be Creative)
            </label>
            <input
              type="text"
              className="form-control"
              id="stageName"
              required
              value={performer.stageName}
              onChange={handleInputChange}
              name="stageName"
            />
          </div>

          <div className="form-group">
            <label htmlFor="song">
            <MusicNote/>
              Song You Will Perform<br/><i>* TYPE UNTIL ONLY 1 SONG, THEN SELECT IT</i>
            </label>
            <div className="add">
            {!songChosen ?
            <input type="text"
            id="song"
            autoComplete="off"
            //ref={props.inputRef}
            className="form-control"
            onChange={(event) => setValue(event.target.value)}
            value={value}
            />
            : "" }
            <div className="searchBack">
                {song.map((song, index) => (
                  <li key={index} >
                      <div className="searchEntry">
                            {/*{song.slice(0,4) === "http" ? "" :*/}
                            <>
                                <input type="text"
                                readOnly
                                name="song"
                                className="form-control"
                                onClick={handleInputClick} 
                                value={performer.song}
                                placeholder={song}
                                />
                            </>
                           {/*} }*/}
                      </div>
                  </li>
                ))}
                <HighlightOffIcon/><button className="btn btn-success" onClick={handleUnChooseSong}>Change Song</button>
            </div>
        </div>

            {/*<input
              type="text"
              className="form-control"
              id="song"
              required
              value={song}
              onChange={handleInputChange}
              name="song"
            />*/}
            
          </div>

          <div className="form-group">
            <label htmlFor="phone">
            <PhoneEnabled/>
              Cellphone (So We Can Text You)
            </label>
            <input
              type="text"
              className="form-control"
              id="phone"
              required
              value={performer.phone}
              onChange={(e) => handleInput(e)}
              name="phone"
            />
          </div>

          <div className="form-group">
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose2}>
          <Alert onClose={handleClose2} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
          <RampLeft/>
          <button onClick={() => {savePerformer()}} className="btn btn-success">
            Join the Line
          </button>
          <br/><br/>
          {userCoins >= payCoins ?
          <>
          <SwitchAccessShortcutIcon/>
          <button onClick={() => {savePerformerVIP()}} className="btn btn-success">
          <span className="pre">Join VIP Line: <img src={symbol} className="symbol" alt="C&#8214;K" />1500&nbsp;&nbsp;</span>
          </button>
          </> : <Link to={"/buy"}><Alert variant="outlined" severity="info">
              VIP Line: 1500 Koins<TouchAppIcon/> GET KOINS!
            </Alert></Link>}
          </div>

          <div id="getInLine">
          </div>
        </div>
      )}
    </div>
    </div>
    </div>
    </>
  );
};

export default AddPerformer;
