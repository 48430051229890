import { useState } from "react";
import firebase from "../firebase";
import PerformerDataService from "../services/PerformerService";
import UserService from "../services/UserService";
import symbol from "../CK_Symbol.svg";
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import EmailIcon from '@mui/icons-material/Email';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import SensorsIcon from '@mui/icons-material/Sensors';
import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

const Performer = (props) => {
  const initialPerformerState = {
    key: "",
    stageName: "",
    song: "",
    songURL: "",
    phone: "",
    published: false,
    vip: false,
    orderNumber: "",
    timeStarted: "",
    timeEnded: "",
    timeRegistered: "",
    coins: 0,
    email: "none",
    avatar: "",
  };

  const [rewardCoins, setRewardCoins] = useState(0);
  const [newCoins, setNewCoins] = useState(0);
  const [currentPerformer, setCurrentPerformer] = useState(initialPerformerState);
  const [timeStarted, setTimeStarted] = useState(initialPerformerState.timeStarted);
  const [timeEnded, setTimeEnded] = useState(initialPerformerState.timeEnded);
  const [message, setMessage] = useState("");

  const { Performer } = props;
  if (currentPerformer.id !== Performer.id) {
    setCurrentPerformer(Performer);
    setMessage("");
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentPerformer({ ...currentPerformer, [name]: value });
  };

  const updatePublished = (status) => {
    if(status){

      /*PerformerDataService.createPrompt('setter', { song: currentPerformer.song, songURL: currentPerformer.songURL })
      .then(() => {
        console.log("The Performance was Logged!");
      })
      .catch((e) => {
        console.log(e);
      });*/

      var d = new Date();
      var d2 = new Date();
        d2.setMonth(d2.getMonth() - 2);
      var timeStartedString = d.toLocaleString();
      var orderTimeAdjustedString = d2.toLocaleString();
      PerformerDataService.update(currentPerformer.id, { published: status, timeStarted: timeStartedString, orderNumber: orderTimeAdjustedString }) 
        .then(() => {
          setCurrentPerformer({ ...currentPerformer, published: status, timeStarted: timeStartedString, orderNumber: orderTimeAdjustedString });
          setTimeStarted(timeStartedString);
          setMessage("The status was updated successfully!");
        })
        .catch((e) => {
          console.log(e);
        });
    }else{
      /*PerformerDataService.createPrompt('setter', { song: false, songURL: false })
      .then(() => {
        console.log("The Performance was Logged!");
      })
      .catch((e) => {
        console.log(e);
      });*/

      const d = new Date();
      const timeEndedString = d.toLocaleString();
      PerformerDataService.update(currentPerformer.id, { published: status, timeEnded: timeEndedString })
        .then(() => {
          setCurrentPerformer({ ...currentPerformer, published: status, timeEnded: timeEndedString });
          setTimeEnded(timeEndedString);
          setMessage("The status was updated successfully!");
        })
        .catch((e) => {
          console.log(e);
        });
    }
    
  };

  const updatePerformer = () => {
    const data = {
      stageName: currentPerformer.stageName,
      song: currentPerformer.song,
      songURL: currentPerformer.songURL,
      orderNumber: currentPerformer.orderNumber,
      timeRegistered: currentPerformer.timeRegistered,
      phone: currentPerformer.phone,
      coins: currentPerformer.coins,
      email: currentPerformer.email,
      avatar: currentPerformer.avatar,
    };

    PerformerDataService.update(currentPerformer.id, data)
      .then(() => {
        setMessage("The Performer was updated successfully!");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const sendUpNextSMS = () => {  
    const url = "https://script.google.com/macros/s/AKfycbzAYpTMuwYZvpLJlTIFe0_eLAgH0K0dq58Q-sKvLKSJANizJRo-MFtnQSh9H5NO0ZwDXw/exec";
    let d2 = new Date();
    //post Coins
    fetch(url,{
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'no-cors', // no-cors, *cors, same-origin 
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //credentials: 'omit', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
       Navigate: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ phone: currentPerformer.phone, message: "⭐⭐" + currentPerformer.stageName + "⭐⭐\n\n  🤩 YOU'RE UP NEXT!!! 🤩\n  🎤 Come to the Stage. 🎤\n\n❤️ -Crypto Karaoke®\n───── ⋆⋅☆⋅⋆ ─────\n❌ PLEASE DO NOT REPLY ❌", performer: currentPerformer.stageName, email: currentPerformer.email, timeStamp: d2.toLocaleString() }) // body data type must match "Content-Type" header
      });
  };

  const sendProblemSMS = () => {  
    const url = "https://script.google.com/macros/s/AKfycbzAYpTMuwYZvpLJlTIFe0_eLAgH0K0dq58Q-sKvLKSJANizJRo-MFtnQSh9H5NO0ZwDXw/exec";
    let d2 = new Date();
    //post Coins
    fetch(url,{
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'no-cors', // no-cors, *cors, same-origin 
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //credentials: 'omit', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
       Navigate: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ phone: currentPerformer.phone, message: "⚠️⚠️" + currentPerformer.stageName + "⚠️⚠️\n\n  ❗ PLEASE SEE THE KJ!!! ❗\n  ❗ There's an issue we need to fix. ❗\n\n❤️ -Crypto Karaoke®\n───── ⋆⋅☆⋅⋆ ─────\n❌ PLEASE DO NOT REPLY ❌", performer: currentPerformer.stageName, email: currentPerformer.email, timeStamp: d2.toLocaleString() }) // body data type must match "Content-Type" header
      });
  };
  

  async function getRewardCoins() {
    const userRef = firebase.collection('performers').doc(currentPerformer.email);
    const doc = await userRef.get();
    if (!doc.exists) {
      console.log('No such performer coins document!');
    } else {
      console.log('Document data:', doc.data().coins);
      setRewardCoins(doc.data().coins);
    }
  }
  getRewardCoins();

  async function get() {
    const userRef = firebase.collection('users').doc(currentPerformer.email);
    const doc = await userRef.get();
    if (!doc.exists) {
      console.log('No such user coins document!');
    } else {
      console.log('Document data:', doc.data().coins);
      setNewCoins(doc.data().coins);
    }
  }
  get();

  const deletePerformer = () => {

      //save Performance to Performance Log
      const dataP = {
        timeRegistered: currentPerformer.timeRegistered,
        timeStarted: timeStarted,
        timeEnded: timeEnded,
        stageName: currentPerformer.stageName,
        song: currentPerformer.song,
        songURL: currentPerformer.songURL,
        orderNumber: currentPerformer.orderNumber,
        phone: currentPerformer.phone,
        coins: rewardCoins,
        email: currentPerformer.email,
        avatar: currentPerformer.avatar,
      };
  
      PerformerDataService.createLog(dataP)
        .then(() => {
          console.log("The Performance was Logged!");
        })
        .catch((e) => {
          console.log(e);
        });

        PerformerDataService.createLogNormal(dataP)
        .then(() => {
          console.log("The Performance was Logged!");
        })
        .catch((e) => {
          console.log(e);
        });

    //save Performance to User Account

    var dt = new Date();
    /*var date = dt.toLocaleDateString();
    var time = dt.toLocaleTimeString();*/
    var dateTime = dt.toString();


    const dataU = {
      timeRegistered: currentPerformer.timeRegistered,
      timeStarted: timeStarted,
      timeEnded: timeEnded,
      stageName: currentPerformer.stageName,
      song: currentPerformer.song,
      songURL: currentPerformer.songURL,
      coins: rewardCoins,
      email: currentPerformer.email,
      dt: dateTime,
    };

    PerformerDataService.createUserPerformaceLog(dataU)
      .then(() => {
        console.log("The Performance was Saved to User Account!");
      })
      .catch((e) => {
        console.log(e);
      });

    //save Earned Koins to User Account
    const data2 = {
      coins: newCoins + rewardCoins,
    };
    
    UserService.updateEarnedCoins(currentPerformer.email, data2)
    .then(() => {
      //delete Performer from list
      PerformerDataService.remove(currentPerformer.id)
      .then(() => {
        props.refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
    })
    .catch((e) => {
      console.log(e);
    });  
      
  };

  const convertToVIPPerformer = () => {
    var d = new Date();
        d.setMonth(d.getMonth() - 1);
    const d2 = d.toLocaleString();
    var data = {
      orderNumber: d2, 
      timeRegistered: currentPerformer.timeRegistered,
      timeStarted: currentPerformer.timeStarted,
      timeEnded: currentPerformer.timeEnded,
      vip: true,
      phone: currentPerformer.phone,
      stageName: currentPerformer.stageName,
      song: currentPerformer.song,
      songURL: currentPerformer.songURL,
      published: false,
      coins: currentPerformer.coins,
      email: currentPerformer.email,
      avatar: currentPerformer.avatar,
    };

    ////Save Performer with New Order
    PerformerDataService.create(data)

    ////Delete current Performer
    PerformerDataService.remove(currentPerformer.id)
      .then(() => {
        props.refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const sendEmail = () => {
  
    const url = "https://script.google.com/macros/s/AKfycbzavnEyAUFItIJiU794pgcE8Q1JsKUVggyXjfa7V34C16yXAVGFkx6s0xUPMHWbmaLT/exec";
    let d2 = new Date();
    //post Coins
    fetch(url,{
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'no-cors', // no-cors, *cors, same-origin 
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //credentials: 'omit', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
       Navigate: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ timeStamp: d2.toLocaleString(), sender: currentPerformer.email, coins: currentPerformer.coins, performer: currentPerformer.stageName, phone: currentPerformer.phone, message: "You are On Deck!", subject: "🎤 😁 YOU'RE UP NEXT!!!", title: "Please Make Your Way To The Stage." }) // body data type must match "Content-Type" header
      });
  }

  return (
    <div>
      {currentPerformer ? (
        <div className="edit-form">
          <h4>
            Performer
          </h4>
          <form>
            <div className="form-group">
              <label htmlFor="stageName">
              <SettingsAccessibilityIcon/>
                &nbsp;Stage Name
              </label>
              <input
                type="text"
                /*className="form-control"*/
                id="stageName"
                name="stageName"
                value={currentPerformer.stageName}
                onChange={handleInputChange}
              />
            </div>
            <label htmlFor="song">
              <MusicNoteIcon/>
                Song
              </label>
            <div className="form-group">
              <input
                type="text"
                /*className="form-control"*/
                id="song"
                name="song"
                value={currentPerformer.song}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
            <label htmlFor="phone">
            <PhoneEnabledIcon/>
              Cellphone
            </label>
            <input
                type="text"
                /*className="form-control"*/
                id="phone"
                required
                value={currentPerformer.phone}
                onChange={handleInputChange}
                name="phone"
              />
                <button
                  id="sms1"
                  type="submit"
                  className="btn"
                  onClick={sendUpNextSMS}>UP NEXT
                </button>
                <button
                  id="sms2"
                  type="submit"
                  className="btn"
                  onClick={sendProblemSMS}>PROBLEM
                </button>
            </div>
            <div className="form-group">
            <label htmlFor="user">
            <EmailIcon/>
            &nbsp;Email
            </label>
            <input
                type="text"
                /*className="form-control"*/
                id="email"
                required
                value={currentPerformer.email}
                onChange={handleInputChange}
                name="email"
              />
                <button
                  id="email"
                  type="submit"
                  className="btn"
                  onClick={sendEmail}>SEND EMAIL
                </button>
            </div>
            <label htmlFor="vip">
              <StarOutlineIcon/>
              &nbsp;VIP
              </label>
            <div className="form-group">
              <input
                type="boolean"
                /*className="form-control"*/
                id="vip"
                required
                value={currentPerformer.vip}
                onChange={handleInputChange}
                name="vip"
              />
            </div>
          
            <div className="form-group">
              <label htmlFor="coins">
              <span className="pre">
              <img src={symbol} className="symbol" alt="C&#8214;K" />&nbsp;&nbsp;Crypto Koins Earned</span>
              </label>
              <input
                type="text"
                /*className="form-control"*/
                id="coins"
                value={rewardCoins}
                onChange={handleInputChange}
                name="coins"
              />
            </div>

            <div className="form-group">
              <label>
                <strong>Status:</strong>
              </label>
              {currentPerformer.published ?
              <SensorsIcon/> :
              <SensorsOffIcon/>
              }
            </div>
          </form>

          {currentPerformer.published ? (
            <button
              className="btn btn-group"
              onClick={() => updatePublished(false)}
            >
              End
            </button>
          ) : (
            <button
              className="btn btn-group"
              onClick={() => updatePublished(true)}
            >
              Start
            </button>
          )}

          <button className="btn btn-group" onClick={deletePerformer}>
            Delete
          </button>

          <button
            type="submit"
            className="btn btn-group"
            onClick={updatePerformer}
          >
            Update
          </button>

          <button
            type="submit"
            className="btn btn-group"
            onClick={convertToVIPPerformer}
          >
            Jump
          </button>

          <p>{message}</p>
        </div>
      ) : (
        <div>
          <br />
          <p>Please click on a Performer...</p>
        </div>
      )}
    </div>
  );
};

export default Performer;
