import Grid from '@mui/material/Grid';
//import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
//import { createTheme } from '@material-ui/core/styles';
//import Typography from '@mui/material/Typography';
import { useCollection } from "react-firebase-hooks/firestore";
import { useState } from 'react';
import PerformerDataService from "../services/PerformerService";
/* THIS MAKES INCREMENT COUNTER WORK */
import firebase from 'firebase/compat/app';
import { db, auth } from "../firebase";
/* THIS MAKES INCREMENT COUNTER WORK */
import "./stage-style.css";
//import Stage from "./Stage";
//import TimeAgo from 'react-timeago';
//import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
//import dna from "./SVG/dna.svg";
import sun from "./SVG/sun.svg";
import music from "./SVG/music-notes-jumping.svg";
//import coinDrop from "./SVG/coins-falling.svg";

import * as React from 'react';
//import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
//import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import ElectricBoltIcon from '@mui/icons-material/Verified';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import SendIcon from '@mui/icons-material/Send';
import Clap from "./SVG/clap3.gif";
import symbol from "../CK_Symbol.svg";
import useSound  from "use-sound";
import ClapSound from "./Sounds/clap.wav";
import ReactPlayer from "react-player";


const Line = () => {
 
/* use react-firebase-hooks */
const [performers, loading, error] = useCollection(PerformerDataService.getAll().orderBy("orderNumber", "asc").limit(1)); // asc or desc
/////////////////

/* get current user email and coins */
db.collection("users").doc(auth().currentUser.email)
  .onSnapshot((doc) => {
      console.log("Subscribed to: ", doc.data());
      setFanCoins(doc.data().coins);
});


////get ching set up
const [playbackRate, setPlaybackRate] = useState(0.75);
const [fanCoins, setFanCoins] = useState(0);
//const [setPlaybackSound] = useState(null);
const [playClap] = useSound(ClapSound, {
  playbackRate,
  volume: 0.8,
});


const handleClap = (value) => {
  setPlaybackRate(value);
  playClap();
  console.log("clap")
};

// RANDOM BG GENERATOR
let bgNum = Math.floor(Math.random() * 9);
let bgBeg = "https://gestaltadvertising.com/crypto-karaoke/SVG/BG-";
let bgSetter = bgBeg + bgNum + ".svg";

//mui
//////BATCH WRITE TRANSACTION TO FIREBASE
const PlusOne = (perfID, perfStageName, amount, sound) => {
  console.log('STARTED COUNTER');
  var dt = new Date();
  var utcDate = dt.toString();
  var date = dt.toLocaleDateString();
  var time = dt.toLocaleTimeString();
  var dateTime = date + " - " + time;

  const batch = db.batch();
  const performerRef = db.collection('performers').doc(perfID);
  const userRef = db.collection('users').doc(auth().currentUser.email);
  const transactionRef = db.collection('users').doc(auth().currentUser.email).collection('transactions').doc(utcDate);
  const increment = firebase.firestore.FieldValue.increment(amount);
  const decrement = firebase.firestore.FieldValue.increment(-amount);
        
  batch.set(performerRef, { coins: increment }, { merge: true });
  console.log('PERFORMER SET');
  batch.set(userRef, { coins: decrement }, { merge: true });
  batch.set(transactionRef, { coins: amount, rewarded: perfStageName, time: dateTime });
  console.log('USER SET');
  //batch.set(userRef2, { rewarded: '20 Crypto Koins to ' +  perfID });
  //console.log('USER TRANSACTION LOGGED');
  batch.commit();
  console.log('FINISHED COUNTER');
  //setPlaybackSound(sound);
  //handleChing(playbackRate + 0.1);

  if(sound === ClapSound){
    handleClap(playbackRate + 0.1);
  }
  /*if(sound === WhistleSound){
    handleWhistle(playbackRate + 0.1);
  }
  if(sound === OvationSound){
    handleOvation(playbackRate + 0.1);
  }*/
}
////////////////////////////////////////////
  
  
    return (
      <>
    {error && <strong>Error: {error}</strong>}
    {loading && <span>Loading...</span>}
    <h2 className='text-center'>Stage</h2>
    {/*<Stage/> */}    
    { !loading &&
    performers &&
    performers.docs.map((Performer, index) => ( /* performers.map */
    <div key={Performer.data().stageName+Performer.data().stageName+index}>             
            {Performer.data().published ?
<div className='container' key={Performer.data().stageName+Performer.data().stageName}>
<div className='container' key={Performer.data().stageName+Performer.data().stageName+Performer.data().stageName+Performer.data().stageName+Performer.data().stageName}>
<ReactPlayer
  url="https://www.youtube.com/embed/live_stream?channel=UC2Gnut6RSWSg6k0wcz8gRNQ&autoplay=1&mute=1"
  controls
  muted={true}
  playing={true}
  className="liveVideo"
/>
</div>
<span className='liveVideo-after'></span>
<Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center"
  className={ Performer.data().vip ? "waitingList" : "nonVIPList" }
  key={Performer.data().stageName+index+index}
>
      <ListItem alignItems="flex-start" key={index+Performer.data().stageName}>
      <h6 key={Performer.data().stageName+Performer.data().stageName+Performer.data().stageName}>{index > 0 ? index : <Avatar alt="" src={music} />}</h6>
      
      { Performer.data().vip ? 
        <ListItemAvatar>
        <Badge anchorOrigin={{vertical: 'bottom', horizontal: 'right', }} overlap="circular" badgeContent={<ElectricBoltIcon/>}> 
          <Avatar alt="" src={ Performer.data().avatar ? Performer.data().avatar : ""} />
        </Badge>
        </ListItemAvatar>
      : 
      <ListItemAvatar>
        <Avatar alt="" src={ Performer.data().avatar ? Performer.data().avatar : sun} />
      </ListItemAvatar>
      }
        
      <ListItemText key={Performer.data().stageName+Performer.data().stageName+Performer.data().stageName+Performer.data().stageName}
        primary={<>{ Performer.data().stageName}<br/>{Performer.data().song }<br/>{<>Koins Earned: {Performer.data().coins}</>}</>}
      />
      </ListItem>

</Grid>

<Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center"
  key={Performer.data().stageName+index+index+index}
>

{fanCoins >= 50 ?
<Fab onClick={() => {PlusOne(Performer.data().email, Performer.data().stageName, 50, ClapSound)}} variant="extended" endicon={<SendIcon />} color="secondary" aria-label="add">
<NavigationIcon sx={{ mr: 1 }} />

  <h4 className='pre'>
  <img className='rewardKoinPic' src={Clap} alt='CK' />
    <img className='rewardKoinButton' src={symbol} alt='CK' /></h4>
  <h4>50 Clap</h4>

<NavigationIcon sx={{ mr: 1 }} />
</Fab>
: ""}

</Grid>

</div>

:
<> 
<img src={bgSetter} alt='Performer Coming Up Soon...'/>
<hr/>
<h5>Performer Coming Up Soon...</h5>
</>
}
</div>
))}
</>
);
};
  
  export default Line;