import{ Component, /*useState, useEffect*/ } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect
} from "react-router-dom";
import { auth } from "./firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Dialog from './components/Dialog';
import NavBar from "./components/NavBar2";
import Home from "./components/Home";
import SignUp from "./components/SignUp";
import SignIn from "./components/SignIn";
import Shop from "./components/Shop";
import Stage from "./components/Card";
import Add from "./components/AddPerformer";
import Admin from "./components/Admin";
import BuyCoins from "./components/BuyCoins";
//import StageMonitor from "./components/StageMonitor";
//import Prompter from "./components/Prompter";
import Leader from "./components/LeaderBoard";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Terms2 from "./components/Terms";
import Privacy2 from "./components/Privacy";
//import Camera from "./components/Camera";
import Spinner from "./components/SVG/dna.svg";
import Line from "./components/Line";
import Transactions from "./components/Transactions";
import Performances from "./components/Performances";
import Orders from "./components/Orders";
import Reservations from "./components/ReservationsList";


function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated === true ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{ pathname: "/buy", state: { from: props.location } }}
            />
          )
      }
    />
  );
}

function PublicRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated === false ? (
          <Component {...props} />
        ) : (
          <Redirect
          to={{ pathname: "/buy", state: { from: props.location } }}
        />
          )
      }
    />
  );
}


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
      loading: true,
      updateCoins: 0,
    };
    // This binding is necessary to make `this` work in the callback
    this.updateCoins = this.updateCoins.bind(this);
  }

  updateCoins(props){
    this.setState(props.updatedCoins);
  }

  componentDidMount() {
    auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({
          authenticated: true,
          loading: false
        });
      } else {
        this.setState({
          authenticated: false,
          loading: false
        });
      }
    });
  }

  componentWillUnmount() {
    //TutorialDataService.getAll().off("value", this.onDataChange);
    clearInterval(this.intervalID)
  }  

  render() {
    return this.state.loading === true ? (
      <div className="spinner-border text-success" role="status">
        <img src={Spinner} alt="" />
  
        <span className="sr-only">Loading...</span>
      </div>
    ) : (
      <>
      <>
      {!auth().currentUser ? 
      <Router>

      <Switch>
        <Route exact path="/" component={Home} />
        <PublicRoute
          path="/signup"
          authenticated={this.state.authenticated}
          component={SignUp}
        />
        <PublicRoute
          path="/signin"
          authenticated={this.state.authenticated}
          component={SignIn}
        />
        <PublicRoute
          path="/terms_of_service"
          authenticated={this.state.authenticated}
          component={Terms}
        />
        <PublicRoute
          path="/privacy_policy"
          authenticated={this.state.authenticated}
          component={Privacy}
        />
        <Route exact path="" component={Home} />
      </Switch>
    </Router>
    :
    <Router>
          <NavBar />
          {auth().currentUser.emailVerified ?
          <>
          <Switch>
            <Route exact path="/" component={Home} />
            <PublicRoute
              path="/signup"
              authenticated={this.state.authenticated}
              component={SignUp}
            />
            <PublicRoute
              path="/signin"
              authenticated={this.state.authenticated}
              component={SignIn}
            />
            <PublicRoute
              path="/terms_of_service"
              authenticated={this.state.authenticated}
              component={Terms}
            />
            <PublicRoute
              path="/privacy_policy"
              authenticated={this.state.authenticated}
              component={Privacy}
            />
            {auth().currentUser.email === process.env.REACT_APP_ADMIN ?
            <PrivateRoute
              path="/admin"
              authenticated={this.state.authenticated}
              component={Admin}
            />
            : ""}

 
            <PrivateRoute
              path="/shop"
              authenticated={this.state.authenticated}
              component={Shop}
            />
            <PrivateRoute
              path="/stage"
              authenticated={this.state.authenticated}
              component={Stage}
            />
            <PrivateRoute
              path="/add"
              authenticated={this.state.authenticated}
              component={Add}
            />
            <PrivateRoute
              path="/buy"
              authenticated={this.state.authenticated}
              component={BuyCoins}
            />
            <PrivateRoute
              path="/leader"
              authenticated={this.state.authenticated}
              component={Leader}
            />
            <PrivateRoute
              path="/line"
              authenticated={this.state.authenticated}
              component={Line}
              />
            <PrivateRoute
              path="/Terms of Service"
              authenticated={this.state.authenticated}
              component={Terms2}
            />
            <PrivateRoute
              path="/Privacy Policy"
              authenticated={this.state.authenticated}
              component={Privacy2}
            />

            <PrivateRoute
              path="/transactions"
              authenticated={this.state.authenticated}
              component={Transactions}
            />
            <PrivateRoute
              path="/performances"
              authenticated={this.state.authenticated}
              component={Performances}
            />
            {auth().currentUser.email === process.env.REACT_APP_ADMIN ?
            <PrivateRoute
              path="/orders"
              authenticated={this.state.authenticated}
              component={Orders}
            />
            : ""
            }
            {auth().currentUser.email === process.env.REACT_APP_ADMIN ?
            <PrivateRoute
              path="/reservations"
              authenticated={this.state.authenticated}
              component={Reservations}
            />
            : ""
            }
          </Switch>
          </>
          : 

          <Dialog/>
          
          }
        </Router>
  }
  </>
      
        </>
      );
  }
}

export default App;
